import React from 'react'
import { Stack, styled, Typography, Radio, StackProps, Box } from "@mui/material"
const { settings } = require("../config")
import ButtonGroup from './ButtonGroup.web';
import { AuthenticationMethod } from "../Settings2Controller"

export function getMobileHeader(onClickTab: any, title: string) {
    return (
        <Stack direction={'row'} alignItems={"center"} sx={{
            display: "none",
            position: "relative",
            "@media(max-width: 991px)": {
                display: "block"
            }
        }}>
            <Box onClick={() => onClickTab()} sx={{
                position: "absolute",
                top: 0,
                left: 0
            }}>
                <svg style={{ cursor: "pointer" }} width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0023 19.0147C10.5123 19.5047 9.72233 19.5047 9.23233 19.0147L0.922334 10.7047C0.532333 10.3147 0.532333 9.68469 0.922334 9.29469L9.23233 0.984688C9.72233 0.494687 10.5123 0.494687 11.0023 0.984688C11.4923 1.47469 11.4923 2.26469 11.0023 2.75469L3.76233 10.0047L11.0123 17.2547C11.4923 17.7347 11.4923 18.5347 11.0023 19.0147Z" fill="#0F172A" />
                </svg>
            </Box>

            <Typography sx={{ color: "#3b83bd", fontSize: "16px", fontWeight: 700, width: "100%", textAlign: "center" }}>{title}</Typography>
        </Stack>
    )
}

const TwoFactorAuthentication = ({ user, selectedValue, changeAuthMethod, onSaveAuthMethod, onClickCancel, tab, onClickTab }: { user: any, selectedValue: AuthenticationMethod, changeAuthMethod: any, onSaveAuthMethod: any, onClickCancel: any, tab: boolean, onClickTab: any }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeAuthMethod(Number(event.target.value));
    };
    const checkedIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#3b83bd" />
        <circle cx="10" cy="10" r="4" fill="white" />
    </svg>
    const uncheckedIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#64748B" />
    </svg>
    return (
        <Block>
            {getMobileHeader(onClickTab, "Two factor authentication")}
            <Stack gap={"62px"} sx={{
                "@media(max-width: 991px)": {
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }
            }}>
                <Stack gap={"13px"} sx={{
                    "@media(max-width: 991px)": {
                        minHeight: "228px"
                    }
                }}>
                    <Typography className='heading'>Choose Your Preferred Method of two factor authentication</Typography>
                    <Typography className='para'>Special codes needed to verify your account and conduct two-factor authentication will be sent to this email address:</Typography>

                    <Stack gap={"20px"}>
                        <Stack direction={"row"} alignItems={"center"} width={"100%"}>
                            <Radio
                                checked={selectedValue === AuthenticationMethod.EMAIL}
                                icon={uncheckedIcon}
                                checkedIcon={checkedIcon}
                                onChange={handleChange}
                                value={AuthenticationMethod.EMAIL}
                                name="radio-buttons"
                                sx={{
                                    '&, &.Mui-checked': {
                                        color: '#64748B',
                                    },
                                }}
                            />
                            <Stack direction={"row"} alignItems={"center"} gap={"32px"} className='radio-label'>
                                <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30 3C30 1.35 28.65 0 27 0H3C1.35 0 0 1.35 0 3V21C0 22.65 1.35 24 3 24H27C28.65 24 30 22.65 30 21V3ZM27 3L15 10.5L3 3H27ZM27 21H3V6L15 13.5L27 6V21Z" fill="#1E293B" />
                                </svg>
                                <div>
                                    <Typography className='heading' style={{ color: "#1E293B", fontSize: "18px" }}>Email</Typography>
                                    <Typography className='para' style={{ color: "#334155" }}>{user.email}</Typography>
                                </div>
                            </Stack>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} width={"100%"}>
                            <Radio
                                checked={selectedValue === AuthenticationMethod.PHONE}
                                icon={uncheckedIcon}
                                checkedIcon={checkedIcon}
                                onChange={handleChange}
                                value={AuthenticationMethod.PHONE}
                                name="radio-buttons"
                                sx={{
                                    '&, &.Mui-checked': {
                                        color: '#64748B',
                                    },
                                }}
                            />
                            <Stack direction={"row"} alignItems={"center"} gap={"32px"} className='radio-label'>
                                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2585 20.7432C16.026 26.5107 21.3705 27.1422 22.9395 27.2007C24.8355 27.2697 26.7705 25.7217 27.6075 24.1362C26.2725 22.5702 24.534 21.3552 22.6305 20.0382C21.507 21.1602 20.1225 23.2452 18.279 22.4982C17.2305 22.0767 14.6415 20.8842 12.3795 18.6207C10.1175 16.3572 8.92499 13.7697 8.50049 12.7227C7.75349 10.8762 9.84449 9.48871 10.968 8.36521C9.65099 6.43021 8.45699 4.64671 6.89399 3.37921C5.28599 4.21921 3.72899 6.13921 3.79949 8.06071C3.85799 9.62971 4.48949 14.9742 10.2585 20.7432ZM22.8285 30.1992C20.6685 30.1197 14.547 29.2737 8.13599 22.8642C1.72649 16.4532 0.881988 10.3332 0.800988 8.17171C0.680988 4.87771 3.20399 1.67821 6.11849 0.428713C6.46945 0.277163 6.85379 0.219464 7.23378 0.261276C7.61378 0.303089 7.97637 0.442977 8.28599 0.667213C10.698 2.42671 12.3615 5.09221 13.791 7.18021C14.0884 7.61439 14.2246 8.13887 14.1761 8.66288C14.1276 9.18689 13.8975 9.67747 13.5255 10.0497L11.4915 12.0852C11.964 13.1277 12.9255 14.9247 14.5005 16.4997C16.0755 18.0747 17.8725 19.0362 18.9165 19.5087L20.949 17.4747C21.3222 17.1012 21.8149 16.8708 22.3409 16.8239C22.8669 16.777 23.3926 16.9166 23.826 17.2182C25.956 18.6942 28.458 20.3337 30.282 22.6692C30.5244 22.9811 30.6786 23.3523 30.7285 23.7442C30.7784 24.136 30.7221 24.5341 30.5655 24.8967C29.31 27.8262 26.133 30.3207 22.8285 30.1992Z" fill="#1E293B" />
                                </svg>
                                <div>
                                    <Typography className='heading' style={{ color: "#1E293B", fontSize: "18px" }}>Phone</Typography>
                                    <Typography className='para' style={{ color: "#334155" }}>{user.phone_number}</Typography>
                                </div>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack gap={"16px"} sx={{
                    "@media(max-width: 991px)": {
                        minHeight: "150px",
                        width: "100%",
                        background: "#fff"
                    }
                }}>
                    <Typography className='para'>Attention! Before pressing “Save” please ensure that you have direct access to your preferred method of authentication.</Typography>
                    <ButtonGroup sx={{
                        "@media(max-width: 991px)": {
                            flexDirection: "column"
                        }
                    }} btn1Title={settings.saveTxt} btn2Title={settings.cancelTxt} onClickContinue={onSaveAuthMethod} onClickCancel={onClickCancel} />
                </Stack>
            </Stack>
        </Block>
    )
}
export const Block: React.FC<StackProps> = styled(Stack)({
    width: "489px",
    height: "100%",
    gap: "62px",
    position: "relative",
    ".radio-label": {
        width: "100%",
        height: "62px",
        borderRadius: "8px",
        padding: "8px 18px",
        border: "1px solid #94A3B8",
    },
    ".heading": {
        color: "#334155",
        fontWeight: 700,
        fontFamily: "Inter",
        fontSize: "16px"
    },
    ".para": {
        color: "#475569",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "12px"
    },
    ".MuiTextField-root": {
        margin: 0,
        height: "56px !important",
    },
    ".MuiOutlinedInput-input": {
        padding: "10px 8px"
    },
    ".MuiInputBase-inputAdornedEnd": {
        padding: "10px 8px"
    },
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        padding: 0
    },
    ".MuiInputBase-inputAdornedStart": {
        padding: 0,
        fontSize: "14px",
        color: "#94A3B8"
    },
    ".MuiInputBase-adornedStart": {
        padding: "8px",
        height: "56px !important",
        color: "#94A3B8"
    },
    ".MuiInputBase-formControl": {
        height: "100%"
    },
    "@media(max-width: 1200px)": {
        width: "100%"
    },
    "@media(max-width: 991px)": {
        gap: "24px",
        justifyContent: "space-evenly",
        width: "100%"
    }
})
export default TwoFactorAuthentication