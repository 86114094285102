import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
interface Props {
    type: "reminder" | "payment_failure" | "none"
    open: boolean;
    onClose: any;
    end_date: string;
}

export const sx = {
    "& .btn": {
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
        fontFamily: "Inter",
        textTransform: "initial",
        letterSpacing: 0,
        height: "56px",
    },
    ".MuiDialogContent-root": {
        overflow: "hidden",
    },
    ".MuiDialog-paperWidthSm": {
        borderRadius: "12px",
        padding: "12px 24px",
        overflow: "hidden",
    },
   
    "#modal-modal-title": {
        fontWeight: 700,
        fontFamily: "Inter",
        fontSize: "18.31px",
    },
    "& .edit-btn-subscription": {
        color: "#fff",
        background: `linear-gradient(99.09deg, #17588b, #3b83bd, #69beff)`,
    },
    "#modal-modal-description": {
        fontFamily: "Inter",
        fontSize: "14px",
        margin: 0,
        fontWeight: 700,
        color: "#64748B"
    },
   
    "& .cancel-btn": {
        background: `#E2E8F0`,
        color: "#64748B",
    },
}
export const CustomModal = ({ open, children, onClose, type }: Partial<Props> & React.PropsWithChildren) => {
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={!!open}
            sx={sx}
        >
            <IconButton
                aria-label="close"
                data-testid="close-button"
                sx={(theme) => ({
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: theme.palette.grey[500],
                    display: type === "payment_failure" ? "none" : "block"
                })}
                onClick={() => onClose("none")}
            >
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.72504 0.283505C9.58492 0.143065 9.39468 0.0641406 9.19629 0.0641406C8.9979 0.0641406 8.80766 0.143065 8.66754 0.283505L5.00004 3.9435L1.33254 0.276005C1.19242 0.135565 1.00218 0.0566406 0.803789 0.0566406C0.605401 0.0566406 0.415163 0.135565 0.275039 0.276005C-0.0174609 0.568505 -0.0174609 1.041 0.275039 1.3335L3.94254 5.001L0.275039 8.6685C-0.0174609 8.961 -0.0174609 9.4335 0.275039 9.726C0.567539 10.0185 1.04004 10.0185 1.33254 9.726L5.00004 6.0585L8.66754 9.726C8.96004 10.0185 9.43254 10.0185 9.72504 9.726C10.0175 9.4335 10.0175 8.961 9.72504 8.6685L6.05754 5.001L9.72504 1.3335C10.01 1.0485 10.01 0.568505 9.72504 0.283505Z" fill="#0F172A" />
                </svg>
            </IconButton>
            {children}
        </Dialog>
    )
}
export default function SubscriptionReminderDialog({ open, onClose, end_date, type }: Props) {
    return (
        <CustomModal open={open} onClose={onClose} type={type}>
            <DialogContent>
                <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} marginTop={"1rem"} gap={"20px"}>
                    <svg width="42" height="37" viewBox="0 0 42 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.5983 2.29688L40.8663 32.2049C41.1297 32.6609 41.2683 33.1782 41.2683 33.7049C41.2683 34.2315 41.1297 34.7488 40.8664 35.2048C40.6031 35.6609 40.2244 36.0396 39.7683 36.3029C39.3123 36.5662 38.795 36.7049 38.2683 36.7049H3.73235C3.20574 36.7049 2.68842 36.5662 2.23237 36.3029C1.77633 36.0396 1.39762 35.6609 1.13433 35.2048C0.87103 34.7488 0.732419 34.2315 0.732422 33.7049C0.732425 33.1782 0.871044 32.6609 1.13435 32.2049L18.4023 2.29688C19.5563 0.296875 22.4423 0.296875 23.5983 2.29688ZM21.0003 5.79687L5.46435 32.7049H36.5363L21.0003 5.79687ZM21.0003 26.0009C21.5308 26.0009 22.0395 26.2116 22.4146 26.5867C22.7896 26.9617 23.0003 27.4704 23.0003 28.0009C23.0003 28.5313 22.7896 29.04 22.4146 29.4151C22.0395 29.7902 21.5308 30.0009 21.0003 30.0009C20.4699 30.0009 19.9612 29.7902 19.5861 29.4151C19.2111 29.04 19.0003 28.5313 19.0003 28.0009C19.0003 27.4704 19.2111 26.9617 19.5861 26.5867C19.9612 26.2116 20.4699 26.0009 21.0003 26.0009ZM21.0003 12.0009C21.5308 12.0009 22.0395 12.2116 22.4146 12.5867C22.7896 12.9617 23.0003 13.4704 23.0003 14.0009V22.0009C23.0003 22.5313 22.7896 23.04 22.4146 23.4151C22.0395 23.7902 21.5308 24.0009 21.0003 24.0009C20.4699 24.0009 19.9612 23.7902 19.5861 23.4151C19.2111 23.04 19.0003 22.5313 19.0003 22.0009V14.0009C19.0003 13.4704 19.2111 12.9617 19.5861 12.5867C19.9612 12.2116 20.4699 12.0009 21.0003 12.0009Z" fill={type === "reminder" ? "#3b83bd" : "#DC2626"} />
                    </svg>
                    <Stack gap={"64px"} sx={{
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Stack gap={"8px"} sx={{
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: "#0F172A", textTransform: "capitalize" }}>
                                {type === "reminder" ? "Your subscription is renewing soon" : "We were unable to complete subscription payment"}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ textAlign: "center", color: "#64748B", width: { lg: "513px" } }}>
                                {type === "reminder" ? `Your subscription will automatically renew on ${end_date}. Please check that your payment details are up to date to maintain access to Advantage PHC.` : `Your subscription was due to renew on ${end_date}, but we were unable to take payment. Please check your payment information and update it to keep using Advantage PHC.`}
                            </Typography>
                        </Stack>
                        <Stack justifyContent={"center"} alignItems={"center"} gap={"24px"} width={"100%"} sx={{
                            flexDirection: { xs: "column", md: "row", lg: "row" }
                        }}>
                            <Button className='btn edit-btn-subscription' data-test-id="btn1" onClick={() => onClose("reminder")}>{type === "reminder" ? "Go to Billing Settings" : "Manage subscription"}</Button>
                            {type === "reminder" && <Button className='btn cancel-btn' data-test-id="btn2" onClick={() => onClose("none")}>{"Cancel"}</Button>}
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
        </CustomModal>
    );
}