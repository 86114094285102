// Customizable Area Start
import React from "react";
import Subscriptionbilling2Controller, {
    Props,
} from "./Subscriptionbilling2Controller";
import { Box, Card, CardContent, Stack, styled, Button } from '@mui/material';
import Layout from "../../landingpage/src/Layout.web";
import { logoIcon } from './assets';

const Logo = styled('img')({
    width: '40px'
});

export default class SetUpSubscription extends Subscriptionbilling2Controller {
    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <Layout id="" navigation={this.props.navigation}>
                <Box
                    flexDirection="column"
                    fontFamily='Inter'
                    marginTop='20px'
                    marginLeft='20px'
                >
                    <h2 style={{ fontSize: '24px', fontWeight: 700, fontFamily: 'Inter' }}>Set up your subscription</h2>
                    <p style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 700, fontFamily: 'Inter', color: '#475569' }}>Please subscribe to Advantage PHC to continue</p>

                    <Box sx={{ display: {sm:'flex' , xs:'block'}, justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
                    <Box sx={{ width:{sm:'444px' , xs:'90%'} }}>
                            <Box style={{border :'grey 1px solid', padding: '8px', borderRadius: '16px' }}  >
                                <Box display="flex" justifyContent="center" marginBottom="16px">
                                    <Stack sx={{ ml: 1, display: { width: '80px' }, paddingLeft: '25px' }}>
                                        <Logo src={logoIcon.default} />
                                    </Stack>
                                </Box>
                                <p style={{ textAlign: 'center', margin: '8px 0', fontSize: '24px', fontWeight: 400, fontFamily: 'Inter' }}>{this.state.subscriptionPlan.title} </p>
                                <p style={{ textAlign: 'center', margin: '8px 0', fontSize: '14px', fontWeight: 400, fontFamily: 'Inter' }}>
                                    {this.state.subscriptionPlan.sub_title}
                                </p>

                                <Box margin='12px' sx={{paddingLeft: '20px'}} >
                                        <div dangerouslySetInnerHTML={{ __html : this.state.subscriptionPlan.description}} style={{ fontSize: '16px', fontWeight: 400, color: '#334155', fontFamily: 'Inter', marginBottom: '6px' }}></div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: {sm:'flex' , xs:'block'},
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: { xs: '20px', sm: '30px' },
                        marginBottom: { xs: '20px', sm: '30px' },
                        gap: { xs: 2, sm: 4 },
                    }}>
                       <Box sx={{ width:{sm:'600px' , xs:'90%'}  , display: {sm:'flex' , xs:'block'}, gap: '50px' }}>
                            <Button
                                onClick={() => this.props.navigation.navigate('AddSubscription')}
                                fullWidth
                                variant="contained"
                                data-test-id='ContinueBtn'
                                sx={{
                                    background: 'linear-gradient(90deg, #17588b, #3b83bd, #69beff)',
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    fontFamily: 'Inter',
                                }}
                                type='submit'
                            >
                                Continue
                            </Button>
                            <Button
                                fullWidth
                                 data-test-id='cancelBtn'
                                onClick={() => this.props.navigation.navigate('EmailAccountLoginBlock')}
                                variant="contained"
                                sx={{
                                    background: '#f1f4f9',
                                    marginTop:{sm:'0px' ,xs:'20px'},
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    fontFamily: 'Inter',
                                    color: '#788393',
                                    ":hover": {
                                        background: '#f1f4f9',
                                    },
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>

                </Box>
            </Layout>
        );
    }
}

// Customizable Area End
