import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

interface Props {
    open: boolean;
    onClose: any;
    onCancelPlan: any;
    renewDate: any;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CancelPlanModal({ open, onClose, onCancelPlan, renewDate }: Props) {

   
    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            sx={{
                ".MuiDialog-paperWidthSm": {
                    borderRadius: "16px",
                    padding: "12px 24px",
                    overflow: "hidden",
                },
                ".MuiDialogContent-root": {
                    overflow: "hidden",
                },
                "#modal-modal-title": {
                    fontWeight: 700,
                    fontFamily: "Inter",
                    fontSize: "18.31px",
                },
                "#modal-modal-description": {
                    margin: 0,
                    fontWeight: 700,
                    fontFamily: "Inter",
                    fontSize: "16px",
                    color: "#64748B"
                },
                "& .btn": {
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Inter",
                    textTransform: "initial",
                    letterSpacing: 0,
                    height: "56px",
                    borderRadius: "8px",
                    padding: "16px",
                    maxWidth: "240px",
                    width: "100%"
                },
                "& .edit-btn": {
                    color: "#fff",
                    background: `linear-gradient(99.09deg, #17588b, #3b83bd, #69beff)`,
                },
                "& .cancel-btn": {
                    background: `#E2E8F0`,
                    color: "#64748B",
                },
            }}
        >
            <IconButton
                aria-label="close"
                data-testid="close-button"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 49,
                    top: 17,
                    color: theme.palette.grey[500],
                })}
            >
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.72406 0.282528C9.58394 0.142089 9.3937 0.0631641 9.19531 0.0631641C8.99692 0.0631641 8.80669 0.142089 8.66656 0.282528L4.99906 3.94253L1.33156 0.275028C1.19144 0.134589 1.0012 0.0556641 0.802813 0.0556641C0.604424 0.0556641 0.414186 0.134589 0.274063 0.275028C-0.0184375 0.567528 -0.0184375 1.04003 0.274063 1.33253L3.94156 5.00003L0.274063 8.66753C-0.0184375 8.96003 -0.0184375 9.43253 0.274063 9.72503C0.566563 10.0175 1.03906 10.0175 1.33156 9.72503L4.99906 6.05753L8.66656 9.72503C8.95906 10.0175 9.43156 10.0175 9.72406 9.72503C10.0166 9.43253 10.0166 8.96003 9.72406 8.66753L6.05656 5.00003L9.72406 1.33253C10.0091 1.04753 10.0091 0.567528 9.72406 0.282528Z" fill="#0F172A" />
                </svg>
            </IconButton>
            <DialogContent>
                <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} gap={"56px"} marginTop={"1rem"}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginBottom: '13px',color: "#DC2626",fontFamily: 'Inter', fontSize:'18.31px', fontWeight: 700, lineHeight: '24.42px'}}>
                            Cancel Plan
                        </Typography>
                        <Typography id="modal-modal-description" 
                         sx={{ color: '#64748B', 
                               mt: 2, 
                               width: {lg: '530px'}, 
                               textAlign: "center", 
                               fontFamily: 'Inter', 
                               fontSize: '16px', fontWeight: 700, 
                               lineHeight: '24px' 
                            }}>
                           Are you sure you want to cancel your plan?
                        </Typography>
                        <Typography 
                        sx={{
                            color: '#64748B', 
                            width: {lg: '530px'}, 
                            textAlign: "center", 
                            fontFamily: 'Inter', 
                            fontSize: '14px', 
                            fontWeight: '700' , 
                            lineHeight: '22px'
                        }}>
                            If you cancel now, you can still access your subscription until 
                            <Typography component='span' 
                            sx={{ color:'#334155',  fontWeight: 700, fontFamily: 'Inter', fontSize: '14px', lineHeight: '22px', ml:'3px'}}>
                             {renewDate}
                            </Typography>
                        </Typography>
                        <Typography 
                         sx={{
                            color: '#64748B', 
                            mt: 2, 
                            width: {lg: '457px'}, 
                            textAlign: "center", 
                            fontFamily: 'Inter', 
                            fontSize: '14px', 
                            fontWeight: '700', 
                            lineHeight: '22px' 
                         }}>
                           <Typography component='span' sx={{color: '#DC2626',fontWeight: 700, fontFamily: 'Inter', fontSize: '14px', lineHeight: '22px'}}>Warning!</Typography> As the subscription term is annual, you will not receive 
                           any refunds for the remainder of the subscription period
                        </Typography>
                    </Stack>
                    <Stack sx={{
                        "@media(max-width: 991px)": {
                            flexDirection: "column",
                            gap: "12px"
                        }
                    }} justifyContent={"center"} alignItems={"center"} direction={"row"} gap={"28px"} width={"100%"} className='modal-btn-group'>
                      <Button className='btn edit-btn' data-testid="btn1" onClick={onClose}>Back</Button>
                      <Button className='btn cancel-btn' data-testid="btn2" onClick={() => onCancelPlan()} style={{ color: "#F87171", background: "#F1F5F9" }}>Cancel Plan</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </BootstrapDialog>
    );
}
