import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

interface Props {
    open: boolean;
    handleClose: any;
}

export const sx = {
    ".MuiDialog-paperWidthSm": {
        borderRadius: "12px",
        padding: "12px 24px",
        overflow: "hidden",
    },
    ".MuiDialogContent-root": {
        overflow: "hidden",
    },
    "#modal-modal-title": {
        fontWeight: 700,
        fontFamily: "Inter",
        fontSize: "18.31px",
    },
    "#modal-modal-description": {
        margin: 0,
        fontWeight: 700,
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#64748B"
    },
    "& .btn": {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Inter",
        textTransform: "initial",
        letterSpacing: 0,
        height: "56px",
        borderRadius: "8px",
        padding: "16px",
        width: "100%"
    },
    
    "& .cancel-btn": {
        background: `#E2E8F0`,
        color: "#64748B",
    },
    "& .edit-btn-success": {
        color: "#fff",
        background: `linear-gradient(99.09deg, #17588b, #3b83bd, #69beff)`,
    },
}

export const CustomModal = ({ open, children, style, handleClose }: { open: boolean, style?: any, handleClose: any } & React.PropsWithChildren) => {
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            sx={{ ...sx, ...style }}
        >
            <IconButton
                aria-label="close"
                data-testid="close-button"
                sx={(theme) => ({
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    color: theme.palette.grey[500],
                })}
                onClick={() => handleClose()}
            >
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.72504 0.283505C9.58492 0.143065 9.39468 0.0641406 9.19629 0.0641406C8.9979 0.0641406 8.80766 0.143065 8.66754 0.283505L5.00004 3.9435L1.33254 0.276005C1.19242 0.135565 1.00218 0.0566406 0.803789 0.0566406C0.605401 0.0566406 0.415163 0.135565 0.275039 0.276005C-0.0174609 0.568505 -0.0174609 1.041 0.275039 1.3335L3.94254 5.001L0.275039 8.6685C-0.0174609 8.961 -0.0174609 9.4335 0.275039 9.726C0.567539 10.0185 1.04004 10.0185 1.33254 9.726L5.00004 6.0585L8.66754 9.726C8.96004 10.0185 9.43254 10.0185 9.72504 9.726C10.0175 9.4335 10.0175 8.961 9.72504 8.6685L6.05754 5.001L9.72504 1.3335C10.01 1.0485 10.01 0.568505 9.72504 0.283505Z" fill="#0F172A" />
                </svg>
            </IconButton>
            {children}
        </Dialog>
    )
}

export default function SuccessDialog({ open, handleClose }: Props) {
    return (
        <CustomModal open={open} handleClose={handleClose}>
            <DialogContent>
                <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} marginTop={"1rem"} gap={"20px"}>
                    <svg width="38" height="29" viewBox="0 0 38 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.7554 23.4669L3.83159 15.4086C3.62438 15.1953 3.37761 15.026 3.10557 14.9104C2.83354 14.7948 2.54165 14.7353 2.24683 14.7353C1.952 14.7353 1.66011 14.7948 1.38808 14.9104C1.11604 15.026 0.869269 15.1953 0.662066 15.4086C0.452335 15.6193 0.285819 15.8703 0.17217 16.1469C0.0585208 16.4236 0 16.7204 0 17.0203C0 17.3201 0.0585208 17.6169 0.17217 17.8936C0.285819 18.1702 0.452335 18.4212 0.662066 18.6319L10.148 28.2789C11.0309 29.1769 12.4572 29.1769 13.3401 28.2789L37.3379 3.89665C37.5477 3.68593 37.7142 3.43497 37.8278 3.15831C37.9415 2.88165 38 2.58481 38 2.28498C38 1.98515 37.9415 1.6883 37.8278 1.41165C37.7142 1.13499 37.5477 0.88403 37.3379 0.673309C37.1307 0.460016 36.884 0.290672 36.6119 0.175093C36.3399 0.059514 36.048 0 35.7532 0C35.4584 0 35.1665 0.059514 34.8944 0.175093C34.6224 0.290672 34.3756 0.460016 34.1684 0.673309L11.7554 23.4669Z" fill="#059669" />
                    </svg>
                    <Stack gap={"64px"} sx={{
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Stack gap={"8px"} sx={{
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: "#0F172A" }}>
                                {"Verification Completed"}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ textAlign: "center", color: "#64748B", width: "443px" }}>
                                {"Congratulations! Your verification process has been completed successfully"}
                            </Typography>
                        </Stack>
                        <Button className='btn edit-btn-success' sx={{ width: "280px" }} data-testid="btn1" onClick={() => handleClose("LandingPage")}>{"Back to Dashboard"}</Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </CustomModal>
    );
}