// Customizable Area Start
import React from "react";
import { styled } from '@mui/material/styles';
import {Grid,Typography,Box,Button} from '@mui/material';
import {pendingSide,errorOutline} from "./assets";

// Customizable Area End

import EmailAccountLoginController, {
    Props,
} from "./EmailAccountLoginController";

export default class PendingVerification extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
      return (
        // Customizable Area Start
        <Grid container>
              <Grid item xs={12} lg={6}>
                  <Box sx={styles.formForgotPassword}>
                      <Grid container sx={styles.inputContainer}>
                          <div style={{ width: '100%' }}>
                              <div style={styles.headImgForgotPassword}>
                                  {<img src={errorOutline} style={{ height: "36px", width: "36px" }} />}
                              </div>
                          </div>

                          <Box sx={styles.containerLabelInputNumber}>
                              <Typography style={styles.head2}>
                                  Account isn't verified yet
                              </Typography>
                              <Typography style={styles.head3}>
                                  Please verify your account before logging in
                              </Typography>
                          </Box>

                          <Box sx={styles.containerLabelInputNumber}>
                              <Button
                                  data-test-id="continue-btn"
                                  variant={"outlined"}
                                  sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                                  style={styles.continueBtn}
                                  onClick={this.continueVerification} >
                                  Continue verification
                              </Button>
                          </Box>

                      </Grid>
                  </Box >
              </Grid>
            <Grid item xs={12} lg={6}>
                <StyledImgForgotPassword src={pendingSide} />
            </Grid>
        </Grid>
        // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledImgForgotPassword = styled('img')(({ theme }) => ({
    width: '100%',
    height: '100%',
    marginTop: '42px',
    marginBottom: '40px',
    [theme.breakpoints.up('md')]: {
        height: '73%',
        borderRadius: '64px 0px 0px 64px',
    },
}));
const styles = {
    containerErrorRegisteredUser:{
        width:"100%",
        display:'flex',
        justifyContent:"center"
    },
    containerLabelInputNumber:{
        display: 'flex',
        flexDirection:"column",
        width: '100%',
        justifyContent: 'center',
        alignItems:"center",
        marginTop:"25px"
    },
    formForgotPassword : {
        heigth:'100%',
        display: 'flex',
        flexWrap:'wrap',
        marginLeft: {xs:'50px', md:'70px', lg:'105px'},
        marginTop: '200px',
        marginBottom: '20px',
        
    },
    headImgForgotPassword : {
        margin:"0 auto",
        height: '76px',
        width: '76px',
        background: "#D6D3D1",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    head2:{
        fontFamily: "Inter",
        fontSize: "30px",
        fontWeight: 400,
        lineHeight: "28px",
        color: "#1E293B",

    },
    head3 : {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "28px",
        color: "#94A3B8",
    },
    inputContainer : {
        heigth: '100%',
        width: '100%',
        padding: {xs:'0 50px 0 0px', md:'0 70px 0 0px', lg:'0 105px 0 0px'},
    },
    errorMsg : {
        color:'#DC2626',
        fontSize:'14px',
        lineheigth: '22px',
        fontWeight:'400',
        align : 'center'
    },
    continueBtn : {
        width: '218px',
        height: '44px',
        fontSize: '15px',
        color: 'white',
        background: 'linear-gradient(to right, #17588b, #3b83bd, #69beff)',
        marginTop: '8px',
        border: 'none',
        padding: "10px 16px",
        borderRadius: "8px",
    },
};


// Customizable Area End
