import React from 'react'
import { Button, styled, Stack } from "@mui/material"

const ButtonGroup = ({ btn1Title, btn2Title, isDisabled, onClickContinue, onClickCancel, sx }: { sx?: any, btn1Title: string, btn2Title: string, isDisabled?: boolean, onClickContinue: any; onClickCancel: any }) => {
    return (
        <Group direction={"row"} width={"100%"} justifyContent={"space-between"} sx={sx}>
            <Button className={`${!!isDisabled ? "cancel-btn" : "edit-btn"} btn`} disabled={!!isDisabled} onClick={() => onClickContinue()}>{btn1Title}</Button>
            <Button className="cancel-btn btn" onClick={() => onClickCancel()}>{btn2Title}</Button>
        </Group>
    )
}

const Group = styled(Stack)({
    ".btn": {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Inter",
        textTransform: "initial",
        letterSpacing: 0,
        maxWidth: "230px",
        width: "100%",
        maxHeight: "56px",
        height: "100%",
        borderRadius: "8px",
        padding: "16px"
    },
    ".Mui-disabled": {
        color: "#64748B !important"
    },
    ".edit-btn": {
        background: `linear-gradient(99.09deg, #17588b, #3b83bd, #69beff)`,
        color: "#fff",
    },
    ".cancel-btn": {
        background: `#E2E8F0`,
        color: "#64748B",
    },
    "@media(max-width: 1200px)": {
        gap: "12px",
        ".btn": {
            maxWidth: "100%",
            maxHeight: "100%",
        }
    }
})

export default ButtonGroup