import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  open: boolean;
  onClose: any;
  title: string;
  data: { id: string, title: string, description: string }[];
}

const TermsModal = ({ open, onClose: setOpen, title, data }: Props) => {
  const [scroll] = React.useState<DialogProps['scroll']>('paper');

  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        ".MuiDialog-paperWidthSm": {
          borderRadius: "16px",
          padding: "12px 24px",
          minHeight: "calc(100% - 64px)",
          minWidth: "651px",
          width: "100%"
        },
        "@media(max-width: 991px)": {
          ".MuiDialog-paperWidthSm": {
            minWidth: "100%"
          }
        },
        "@media(max-width: 420px)": {
          ".MuiDialog-paperWidthSm": {
            padding: "12px 0",
          },
          ".MuiDialogTitle-root": {
            display: "flex",
            fontSize: "1rem"
          },
        },
      }}
    >
      <Stack gap={"30px"}>
        <DialogTitle id="scroll-dialog-title" style={{ height: "56px", textAlign: "center", color: "#3b83bd", fontSize: "18.31px", fontFamily: "Inter", fontWeight: 700, textTransform: "uppercase", position: "relative" }}>
          {title}
          <div style={{ position: "absolute", top: "35%", right: "0" }}>
            <Button onClick={() => setOpen()} data-testid="close-button">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.72406 0.282528C9.58394 0.142089 9.3937 0.0631641 9.19531 0.0631641C8.99692 0.0631641 8.80669 0.142089 8.66656 0.282528L4.99906 3.94253L1.33156 0.275028C1.19144 0.134589 1.0012 0.0556641 0.802813 0.0556641C0.604424 0.0556641 0.414186 0.134589 0.274063 0.275028C-0.0184375 0.567528 -0.0184375 1.04003 0.274063 1.33253L3.94156 5.00003L0.274063 8.66753C-0.0184375 8.96003 -0.0184375 9.43253 0.274063 9.72503C0.566563 10.0175 1.03906 10.0175 1.33156 9.72503L4.99906 6.05753L8.66656 9.72503C8.95906 10.0175 9.43156 10.0175 9.72406 9.72503C10.0166 9.43253 10.0166 8.96003 9.72406 8.66753L6.05656 5.00003L9.72406 1.33253C10.0091 1.04753 10.0091 0.567528 9.72406 0.282528Z" fill="#0F172A" />             </svg>
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{display: "flex", flexDirection: "column", gap: "24px"}}
          >
            {data?.map(item => <Stack key={item.id}>
              <Typography style={{ color: "#1C1917", fontSize: "15px", fontFamily: "Inter", fontWeight: 700, textTransform: "capitalize" }}>{item.title}</Typography>
              <div style={{ color: "#1C1917", fontSize: "10px", fontFamily: "Inter", fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: item.description }} />
            </Stack>)}
          </DialogContentText>
        </DialogContent>
      </Stack>
    </Dialog>
  )
}

export default TermsModal