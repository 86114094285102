// Customizable Area Start
import React from "react";
import Box from '@mui/material/Box';
import { Stepper, Step, StepLabel, Typography, Grid,ListItem, ListItemText, IconButton , CircularProgress } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Layout from "../../landingpage/src/Layout.web";
import CustomFormController, { Props } from "./CustomFormController.web";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const steps = [
  'New case',
  'Awaiting customer response',
  'Awaiting provider response',
  'Awaiting insurance response',
  'Awaiting CSR',
  'Advocate',
  'Team view',
  'Closed',
]; 

const pdfFile = require("../assets/pdfFile.png");
const worldFile = require("../assets/wordfile.png");
const jpgFile = require("../assets/jpgFile.png");
const dowloadImage = require("../assets/download.png");

export default class ViewCaseDetails extends CustomFormController {
  constructor(props: Props) {
    super(props);

  }

  render() {
    const {viewCaseDetailsData}=this.state
    return (
      <Layout id="" navigation={this.props.navigation}>
        <div style={{ fontFamily: 'inter' , padding:'30px' }}>
        <Box sx={{ display: { xs: 'none', sm: 'block' }  , paddingLeft:{sm:'18px' , xs:'0px'}}}>
          <div
           onClick={()=>this.props.navigation.navigate("CustomForm")}
          style={{
            display: 'flex',
            alignItems: 'center',
            height: "26px",
            width: '50px',
            marginTop: '10px',
            
          }}
          >
            <ArrowBackIosIcon style={{ color: '#0F172A' }} />
            <div style={{
              fontSize: '18px',
              color: '#0F172A'
            }}>Back</div>
          </div>
        </Box>
      <Box sx={{paddingLeft:{sm:'40px' , xs:'0px'}}}>      
      <Typography variant="h5" style={{ fontWeight: 700, color: '#333333', marginTop: '20px' , fontSize: '24px',fontFamily:'Inter' }}>
            Case details
          </Typography>
      </Box>
          
        <Box sx={{paddingLeft : {sm:'55px' , xs:'0px'}}}>
        <Typography style={{ fontWeight: 700, fontSize: '14px', color: '#64748B', marginTop: '20px',fontFamily:'Inter'  }}>
            Case status
          </Typography>
        </Box>
          <Box sx={{display:{sm:'none' , xs:'flex'} , paddingLeft : {sm:'70px' , xs:'0px'} , gap:'20px'  }} alignItems="center">
     
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={100}
          size={100}
          thickness={6}
          style={{ color: '#FFF6D4' }} 
        />
        <CircularProgress
          variant="determinate"
          value={(this.state.stepperIndex+1)*12.5}
          size={100}
          thickness={6}
          style={{ color: '#e89d24', position:'absolute' }} 
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="body1"
            component="div"
            style={{ color: '#3b83bd', fontSize: '14px' , fontWeight:700 ,fontFamily:'Inter' }}
          >
            {`${this.state.stepperIndex+1} of ${8}`}
          </Typography>
        </Box>
      </Box>

      <Box marginLeft="8px">
        <Typography
          variant="body2"
          style={{ fontWeight: 'bold', fontSize: '14px',fontFamily:'Inter' }}
        >
          Step {this.state.stepperIndex+1}
        </Typography>
        <Typography
          variant="caption"
          style={{ fontSize: '12px', color: '#6c757d',fontFamily:'Inter' }}
        >
          {steps[this.state.stepperIndex]}
        </Typography>
      </Box>
    </Box>
          <Box sx={{ width: '100%', fontFamily: 'inter', marginTop: '10px' , display:{sm:'flex' , xs:'none' }}}>
            <Stepper activeStep={this.state.stepperIndex} alternativeLabel sx={{
              "& .Mui-active .MuiStepConnector-line": {
                borderColor: "#3b83bd",
                borderTopWidth: "2px",

              },
              '& .MuiSvgIcon-root .Mui-active ': {
                color: 'blue', 
              },
              "& .Mui-completed .MuiStepConnector-line": {
                borderColor: "#3b83bd",
                borderTopWidth: "2px",

              },
              "& .MuiStepConnector-line": {
                borderColor: "##CBD5E1",
                borderTopWidth: "2px"
              },
              width: '100%',
            }} >
              {steps.map((label) => (
                <Step key={label} sx={{
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: '#3b83bd', 
                  },
                  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                  {
                    color: '#c9d5e1',
                  },
                  '& .MuiStepLabel-root .Mui-active': {
                    color: '#3b83bd',
                    border: '1px solid #3b83bd',
                    borderRadius: '50%'
                  },

                  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                  {
                    color: '#94A3B8', 
                    border: '0px solid yellow',
                  },
                  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                    fill: 'white', 
                  },
                  '& .QontoStepIcon-circle': {
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: 'currentColor',
                  },
                }}>
                  <StepLabel icon={<FiberManualRecordIcon />} sx={{fontFamily:'Inter' , fontSize: '12px', fontWeight: 400, color: '#94A3B8' }}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ paddingTop: '30px', width: {xs:'100%' , sm:'53%'} }}>
              <Typography style={{ fontWeight: 700, color: '#64748B', marginBottom: '5px', fontSize: '14px', fontFamily:'Inter' }}>
                Short description
              </Typography>
              <Typography variant="body1" style={{ color: '#1E293B', marginBottom: '20px', fontSize: '14px', fontWeight: 400,fontFamily:'Inter' }}>
               {viewCaseDetailsData?.attributes?.short_description}
              </Typography>

             <Box sx={{marginBottom:{sm:'20px' , xs:'0px'}}}>
             <Grid container spacing={2} >
                <Grid item xs={12}  sm={6} >
                  <Typography style={{ fontWeight: 700, color: '#64748B', marginBottom: '5px', fontSize: '14px',fontFamily:'Inter' }}>
                    Preferred method of contact
                  </Typography>
                  <Typography style={{ color: '#1E293B',fontSize: '14px', fontWeight: 400,fontFamily:'Inter' }}>
                  {viewCaseDetailsData?.attributes?.preferred_method_of_contact}
                  </Typography>
                </Grid>
                <Grid item xs={12}  sm={6}>
                  <Typography style={{ fontWeight: 700, color: '#64748B', marginBottom: '5px', fontSize: '14px',fontFamily:'Inter' }}>
                    Case submitted for
                  </Typography>
                  <Typography style={{ color: '#1E293B', marginBottom: '20px', fontSize: '14px', fontWeight: 400,fontFamily:'Inter' }}>
                   {viewCaseDetailsData?.attributes?.submitting_for}
                  </Typography>
                </Grid>
              </Grid>
             </Box>

              <Typography style={{ fontWeight: 700, color: '#64748B', marginBottom: '5px', fontSize: '14px',fontFamily:'Inter' }}>
                Details of the issue
              </Typography>
              <Typography style={{ color: '#1E293B', marginBottom: '20px', fontSize: '14px', fontWeight: 400,fontFamily:'Inter' }}>
               {viewCaseDetailsData?.attributes?.details_of_issue}
              </Typography>

              <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 700, color: '#64748B', marginBottom: '5px', fontSize: '14px',fontFamily:'Inter' }}>
                    Money saved
                  </Typography>
                  <Typography style={{ color: '#1E293B', marginBottom: '20px', fontSize: '14px', fontWeight: 400,fontFamily:'Inter' }}>${viewCaseDetailsData?.attributes?.money_saved}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 700, color: '#64748B', marginBottom: '5px', fontSize: '14px',fontFamily:'Inter' }}>
                    % Saved
                  </Typography>
                  <Typography style={{ color: '#1E293B', marginBottom: '20px', fontSize: '14px', fontWeight: 400,fontFamily:'Inter' }}>{viewCaseDetailsData?.attributes?.percent_saved} %</Typography>
                </Grid>
              </Grid>

              <div style={{ marginTop: '20px' }}>
                <Typography style={{ fontWeight: 700, color: '#64748B', marginTop: '10px', fontSize: '14px',fontFamily:'Inter' }}>
                  Uploaded documents
                </Typography>
                {viewCaseDetailsData?.attributes?.documents?.map((doc: any, ind: number) => {
                  return (
                    <ListItem
                      key={ind}
                      sx={{
                        background: '#f1f4f9',
                        padding: '8px 12px',
                        borderRadius: '12px',
                        boxShadow: 'revert',
                        marginBottom: '10px',
                        position: 'relative'
                      }}
                    >

                      <img
                        src={doc?.file_type === 'pdf' ? pdfFile.default : doc?.file_type === 'jpg' ? jpgFile.default : worldFile.default}
                        alt="file icon"
                        style={{ width: '32px', height: '32px', marginRight: '5px' }}
                      />
                      <ListItemText sx={{ fontSize: '14px', fontWeight: 400 }} primary={doc.name} secondary={doc.size} />
                      <IconButton
                        onClick={() => this.downloadFile(doc)}
                        sx={{ cursor: 'pointer' }}
                        color='error'
                        edge="end"
                        aria-label="delete"
                        data-test-id="removeFile"
                      >
                        <img
                          src={dowloadImage.default}
                          alt="file icon"
                          style={{ width: '83px', marginRight: '5px', height: '24px', }}
                        />
                      </IconButton>
                    </ListItem>
                  )
                })}
              </div>
            </Box>
          </Box>
        </div>
      </Layout>
    );
  }
}

// Customizable Area End
