// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  IconButton,
  List,
  Fab,
  ListItem,
  ListItemText,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  LinearProgress
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from "yup";
import { styled } from "@mui/styles";
import { Formik, FormikErrors, FormikTouched} from "formik";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CustomFormController, {
  Props,
  Dropdown,
  AddCaseTouched,
  AddCaseError,
} from "./CustomFormController.web";
import Layout from "../../landingpage/src/Layout.web";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Webcam from "react-webcam";
import { ToastContainer } from "react-toastify";
import ReplayIcon from '@mui/icons-material/Replay';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const validationSchema = Yup.object({
  caseDescription: Yup.string().required('Please provide short description ').max(128, 'Maximum 128 characters allowed'),
  courtLocation: Yup.string().required('  '),
  proceedingDate: Yup.string().required('   '),
  caseDetails: Yup.string().required('Please provide details of the issue').max(300, 'Maximum 300 characters allowed'),
});
const logo = require("../assets/image_logo.png");
const uploadLogo = require("../assets/cloud_upload.png");
const jpgFile = require("../assets/jpgFile.png");
const pdfFile = require("../assets/pdfFile.png");
const worldFile = require("../assets/wordfile.png");

const videoConstraints = {
  width: 400,
  height:600,
  facingMode: "environment"
};

// Customizable Area End

export default class AddNewCaseForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.webcamRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start

  getErrorMessage = (
    touched: FormikTouched<AddCaseTouched>,
    errors: FormikErrors<AddCaseError>,
    value: string
  ) => {
    return (
      touched[value as keyof AddCaseError] &&
      errors[value as keyof AddCaseError] && (
        <Typography
          style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
        >
          {errors[value as keyof AddCaseError]}
        </Typography>
      )
    );
  };

  componentDidUpdate(prevProps :any, prevState:any) {
    if (this.state.error && prevState.error !== this.state.error) {
      setTimeout(() => {
        this.setState({ error: null });
      }, 2000);
    }
  }
  previewScreenContent = (values:any, setFieldValue:any) => {
    let previewScreenContent;
    if (this.state.isShowPreviewScreen) {
      previewScreenContent = (
        <Box>
          <div style={{ height: '70%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={this.webcamRef.current?.getScreenshot()}
                alt="file icon"
              />
            </div>
          </div>
          <div
            style={{
              borderTop: '1px solid gray',
              paddingTop: '20px',
              width: '100%',
            }}
          >
            <div style={{ padding: '10px' }}>
              <Button
                fullWidth
                type="button"
                onClick={() => {
                  this.addImageUrl(setFieldValue, values.files);
                }}
                variant="contained"
                data-test-id="addImageBtn"
                sx={{
                  background: 'linear-gradient(90deg, #17588b, #3b83bd, #69beff)',
                  fontWeight: 600,
                  textTransform: 'none',
                  fontFamily: 'Inter',
                }}
              >
                <AddAPhotoIcon />
                <span style={{ paddingLeft: '10px' }}>Add Photo</span>
              </Button>
            </div>
            <div style={{ padding: '10px' }}>
              <Button
                fullWidth
                type="button"
                onClick={() => {
                  this.setState({ isShowPreviewScreen: false });
                  this.setState({ isShowCameraPage: true });
                }}
                variant="contained"
                data-test-id="retakeBtn"
                sx={{
                  backgroundColor: '#94a3b8',
                  '&:hover': {
                    backgroundColor: '#94a3b8',
                  },
                  fontWeight: 600,
                  textTransform: 'none',
                  fontFamily: 'Inter',
                }}
              >
                <ReplayIcon />
                <span style={{ paddingLeft: '10px' }}>Retake</span>
              </Button>
            </div>
          </div>
        </Box>
      );
    } else {
      previewScreenContent = (
        <Box>
          {this.state.isWebcamReady ? null : (
            <div style={{ height: '90%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CameraAltOutlinedIcon
                  sx={{
                    height: '90px',
                    width: '90px',
                    color: '#F1F5F9',
                    marginTop: '40%',
                  }}
                />
              </div>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Webcam
              audio={false}
              ref={this.webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              onUserMedia={(e) => {
                this.setState({ isWebcamReady: e.active || false });
              }}
            />
          </div>
          <div style={{ borderTop: '1px solid gray', paddingTop: '20px' }}>
            <div style={{ padding: '10px' }}>
              <Button
                disabled={!this.state.isWebcamReady}
                fullWidth
                type="button"
                onClick={() => {
                  this.capture(values);
                }}
                variant="contained"
                data-test-id="createCaseBtn"
                sx={{
                  background: 'linear-gradient(90deg, #17588b, #3b83bd, #69beff)',
                  fontWeight: 600,
                  textTransform: 'none',
                  fontFamily: 'Inter',
                }}
              >
                <CameraAltOutlinedIcon />
                <span style={{ paddingLeft: '10px' }}>Take a Photo</span>
              </Button>
            </div>
          </div>
        </Box>
      );
    }

return previewScreenContent;

  }
  // Customizable Area End

  render() { 
    return (
      // Customizable Area Start
      <Layout id="" navigation={this.props.navigation}>
        <ToastContainer/>
    <div 
       style={{fontFamily:'Inter'}}
      >
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <div
          data-test-id='navigateBtn'
           onClick={()=>this.props.navigation.navigate("CustomForm")}
          style={{
            display: 'flex',
            alignItems: 'center',
            height: "26px",
            width: '50px',
            marginTop: '20px',
            marginLeft: '20px'
          }}
          >
            <ArrowBackIosIcon style={{ color: '#0F172A', cursor: 'pointer' }} />
            <div style={{
              fontSize: '18px',
              color: '#0F172A',
              cursor: 'pointer'
            }}>Back</div>
          </div>
        </Box>
      
        <Box
          sx={{
            padding: { xs: '15px', sm: '45px' },
            backgroundColor: '#ffffff',
            borderRadius: '8px'
          }}
        >
       {!this.state.isShowCameraPage && <Typography
            sx={{
              fontWeight: 700,
              fontSize: { xs: '18px', sm: '24px' },
              textAlign: 'left',
              fontFamily:'Inter'
            }}
          >
            New Case
          </Typography>}
         {!this.state.isShowCameraPage &&  <p
            style={{
              fontWeight: 400,
              color: '#a9b0b7',
              margin: '0px',
              fontSize:'18px',
              fontFamily:'Inter'
            }}
          >
            To create a new case please fill in all the fields below
          </p>}
          <Box
            sx={{
              marginTop: { xs: '5px', sm: '10px' },
              padding: { xs: '0px', sm: '40px' }
            }}
          >
            <Formik  
            enableReinitialize
            data-test-id='formik'
              initialValues={this.state.formValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                this.setState({ isOpenConfrimationDialog: true });
                this.setState({createCaseData : values}) 
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                handleReset
              }) => (
                <form onSubmit={handleSubmit}>
              {!this.state.isShowCameraPage ?
                <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel sx={{ fontWeight: 700, fontSize: '14px',fontFamily:'Inter' }} htmlFor="caseDescription">
                        Short description
                        <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>
                          *
                        </Typography>
                      </InputLabel>
                      <TextField
                        data-test-id="caseDescription"
                        name="caseDescription"
                        placeholder="Describe your case"
                        value={values.caseDescription}
                        onChange={handleChange} 
                        onBlur={handleBlur}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          maxLength: 128, 
                          sx: {
                            height: "0px",
                            fontFamily:'Inter',
                            outline: 'none',
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent',
                            },
                          },
                        }}
                      />
                        {this.getErrorMessage(touched, errors, "caseDescription")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ fontWeight: 700, fontSize: '14px',fontFamily:'Inter' }} htmlFor="courtLocation">
                        Preferred method of contact
                        <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>
                          *
                        </Typography>
                      </InputLabel>
                      <Select
                        fullWidth
                        data-test-id="courtLocation"
                        name="courtLocation"
                        variant="outlined"
                        displayEmpty
                        value={values.courtLocation}
                        sx={{ height: '35px' }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.courtLocation && Boolean(errors.courtLocation)} 
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .MuiMenuItem-root': {
                                '&:hover': {
                                  backgroundColor: '#fefbea',
                                },
                                '&.Mui-selected': {
                                  backgroundColor: '#fefbea', 
                                  '&:hover': {
                                    backgroundColor: '#fefbea',
                                  },
                                  fontFamily:'Inter'
                                },
                              },
                              '& .MuiMenuItem-root:nth-of-type(1)': {
                                backgroundColor: values.courtLocation === '' ? '#fefbea' : 'white',
                              },
                            },
                          },
                        }} 
                        renderValue={(selected) => { 
                          if (selected.length === 0) {
                            return <span>Select option</span>;
                          }
                      
                          return selected ;
                        }}
                      >
                        <MenuItem value="Email">Email</MenuItem>
                        <MenuItem value="Phone number">Phone number</MenuItem>
                      </Select>
                    
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel sx={{ fontWeight: 700, fontSize: '14px',fontFamily:'Inter' }} htmlFor="proceedingDate">
                        Who are you submitting this case for
                        <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>
                          *
                        </Typography>
                      </InputLabel>
                      <Select
                        fullWidth
                        data-test-id="proceedingDate"
                        name="proceedingDate"
                        variant="outlined"
                        displayEmpty
                        sx={{ height: '35px', outline: '0px' }}
                        value={values.proceedingDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.proceedingDate && Boolean(errors.proceedingDate)}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              fontFamily:'Inter',
                              '& .MuiMenuItem-root': {
                                '&:hover': {
                                  backgroundColor: '#fefbea', 
                                },
                                '&.Mui-selected': {
                                  backgroundColor: '#fefbea', 
                                  '&:hover': {
                                    backgroundColor: '#fefbea', 
                                  },
                                },
                              },
                              '& .MuiMenuItem-root:nth-of-type(1)': {
                                backgroundColor: values.courtLocation === '' ? '#fefbea' : 'white', 
                              },
                            },
                          },
                        }}
                        renderValue={(selected) => { 
                          if (selected.length === 0) {
                            return <span>Select option</span>;
                          }
                      
                          return selected ;
                        }}
                      >
                      {this.state.caseAccounts.map((account: any, index: number) => (
                            <MenuItem key={index} value={account?.first_name?.concat(" ", account?.last_name)}>
                              {account?.first_name?.concat(" " ,account?.last_name)}
                            </MenuItem>
                          ))}
                      </Select>
                    
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel sx={{ fontWeight: 700, fontSize: '14px',fontFamily:'Inter', }} htmlFor="caseDetails">
                        Details of the Issue
                        <Typography component="span" sx={{ color: 'red', marginLeft: '4px' }}>
                          *
                        </Typography>
                      </InputLabel>
                      <TextField
                        fullWidth
                        data-test-id="caseDetails"
                        name="caseDetails"
                        variant="outlined"
                        placeholder="Provide details of the case"
                        multiline
                        rows={3}
                        value={values.caseDetails}
                        onChange={handleChange}
                        onBlur={handleBlur} 
                        inputProps={{
                          maxLength: 300, 
                          sx: {
                            height: "0px",
                            outline: 'none',
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent',
                            },
                            fontFamily:'Inter',
                          },
                        }}
                      />
                      {this.getErrorMessage(touched, errors, "caseDetails")}
                    </Grid>
                  </Grid>
                   <Box  sx={{
                      padding: { xs: '0px', sm: '25px' },
                        position:'relative'
                    }}>
                    <Box
                      sx={{
                        fontWeight: { xs: 400, sm: 700 },
                        marginTop: { xs: '10px', sm: '25px' },
                        fontSize: { xs: '15px', sm: '24px' },
                        fontFamily:'Inter',
                      }}
                    >
                      Upload a document
                      {values.files.length ? (
                        <span
                          style={{
                            color: values.files.length === 5 ? 'red' : 'black',
                            fontWeight: 600,
                            paddingLeft: '8px',
                          }}
                        >
                          {values.files.length}/5
                        </span>
                      ) : ''}
                    </Box>
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }} >
                    <p
                      style={{
                        fontWeight: 400,
                        color: '#a9b0b7',
                        fontSize:'18px',
                        fontFamily:'Inter',
                      }}
                    >
                      You can attach up to 5 documents for your case
                    </p>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'flex' },
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Box
                      sx={{

                        border: '1px dashed grey',
                        padding: { xs: '10px', sm: '20px' },
                        textAlign: 'center',
                        width: { xs: '100%', sm: '100%' },
                        marginTop: '7px',
                        backgroundColor: '#ffffff',
                        borderRadius: '8px',
                      }}
                    >
                        <Fab sx={{
                          backgroundColor: '#3b83bd', 
                          '&:hover': {
                              backgroundColor:'#3b83bd',
                            },
                          color: '#fff',
                          width:'70px',
                          height:'70px',
                        }} aria-label="upload">
                          <img
                            src={uploadLogo.default}
                            alt="upload icon"
                            style={{
                              width: '26.95px',
                              height: '19.6px',
                            }}
                          />
                        </Fab>
                      <div style={{ display: 'flex', justifyContent: 'center' }} >
                        <Button
                          variant="contained"
                          component="label"
                          sx={{
                            display: 'block',
                            marginTop: '10px',
                            width: '120px',
                            height:'30px',
                            padding:'0px',
                            fontSize: '16px',
                            cursor: 'pointer',
                            textTransform: 'none',
                            fontWeight: 600,
                            alignSelf: 'center',
                            fontFamily:'Inter',
                            color: 'white',
                            backgroundColor: '#94a3b8',
                            '&:hover': {
                              backgroundColor:'#94a3b8',
                            },
                          }}
                        >
                          Choose file
                          <input
                            type="file"
                            accept=".pdf, .jpg, .doc, .docx" 
                            data-test-id='file'
                            hidden
                            multiple
                            onChange={(event) => {
                              this.handleFileChange(event, setFieldValue, values.files)
                            }}
                          />
                        </Button> 
                      </div>
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: '10px',
                          color: 'grey',
                          fontFamily:'Inter',
                        }}
                      >
                        Supported file: pdf, Word, jpg
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: '5px',
                          color: 'grey',
                          fontFamily:'Inter',
                        }}
                      >
                        Maximum size: 30 Mb
                      </Typography>

                      {this.state.error && (
                          <Dialog data-test-id="errorDialog" open={true} onClose={() => this.setState({ error: null })}>
                            <Box
                              sx={{
                                padding: { xs: '10px', sm: '15px' },
                                textAlign: 'center',
                              }}
                            >
                              <div style={{
                                height: '25px',
                                width: '25px',
                                alignItems: 'center',
                                marginTop: '15px',
                                marginLeft: '10px'
                              }}>
                                <img style={{
                                  height: '40px',
                                  width: '40px',
                                }} src={logo.default} />
                              </div>
                              <ReportProblemOutlinedIcon sx={{ fontSize: '40px', color: 'red',fontFamily:'Inter', }} />
                                {this.state.error === 'Maximum file size is 30 Mb.' ? <p style={{ fontWeight: 700, fontSize: '18px' }}>File Size Limit is Exceeded</p> : this.state.error === 'Invalid file type. Only pdf, jpg, word files are allowed.' ? <p style={{ fontWeight: 700, fontSize: '18px' }}>Invalid file</p> : <p style={{ fontWeight: 700, fontSize: '18px' }}>Limit Reached</p>}
                              
                              <Typography variant="body1" sx={{ marginTop: '10px',fontFamily:'Inter', }}>
                                <p style={{ fontSize: '14px', color: '#94A3B8', fontWeight: 700 }}>{this.state.error}</p>
                              </Typography>
                            </Box>
                          </Dialog>
                      )}
                    </Box>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      display: { xs: 'block', sm: 'none' },
                      marginTop: '5px',
                      color: 'grey',
                      fontSize: '13px',
                      fontFamily:'Inter',
                    }}
                  >
                    Supported file: pdf, Word, jpg
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: '3px',
                      color: 'grey',
                      display: { xs: 'block', sm: 'none' },
                      fontSize: '13px',
                      fontFamily:'Inter',
                    }}
                  >
                    Maximum size: 30 Mb
                  </Typography>
                      {this.state.isShowCamera && <div style={{ backgroundColor: '#888888', position: 'absolute', top: '0px', right: '10px', zIndex: 1, borderRadius: '16px',  }}>
                      <Button 
                    variant="contained"
                    component="label"
                    sx={{
                      cursor: 'pointer',
                       paddingLeft:'20px',
                       paddingRight:'20px',
                      textTransform: 'capitalize',
                      fontSize:'14px',
                      color: 'white',
                      backgroundColor:'transparent',
                      boxShadow:'none',
                    }}
                  >
                    <span style={{ alignSelf: 'center' }}>Choose file</span>
                    <input
                      type="file"
                      data-test-id="file"
                      hidden
                      multiple
                      onChange={(event) => {
                        this.handleFileChange(event, setFieldValue, values.files)
                      }}
                    />
                  </Button> 
                        <p  data-test-id='cameraNavigateBtn' onClick={()=>{
                          if(values.files.length === 5){
                            this.setState({ error: 'You can attach up to 5 documents for your case.' });
                          }else{
                            this.setState({'isShowCameraPage' : !this.state.isShowCameraPage})
                          }
                        }} style={{ padding: '5px',fontSize:'14px', paddingLeft:'20px',paddingRight:'20px',margin:'0px',  color: '#fff', borderBottom: '1px solid gray',  borderTop: '1px solid gray',cursor:'pointer' }}>Take a photo</p>
                      </div>}
                  <Button
                    variant="contained"
                    data-test-id='CameraOpenBtn'
                    component="label"
                    onClick={()=>{this.setState({isShowCamera : !this.state.isShowCamera })}}
                    sx={{
                      display: { xs: 'flex', sm: 'none' },
                      justifyContent: 'center',
                      marginTop: '10px',
                      width: '100%',
                      fontSize: '16px',
                      cursor: 'pointer',
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      alignSelf: 'center',
                      fontFamily:'Inter',
                      color: 'white',
                      backgroundColor: '#94a3b8',
                      '&:hover': {
                        backgroundColor: '#94a3b8',
                      },
                    }}
                  >
                    <span style={{ alignSelf: 'center' }} >Choose file</span>
                  </Button>
                  <Box sx={{ display: { xs: 'flex', sm: 'none' }, marginTop: '25px' }} >
                    {values.files.length ? (
                      <span
                        style={{
                          color: values.files.length === 5 ? 'red' : 'black',
                          fontWeight: 600,
                        }}
                      >
                        {values.files.length}/5
                      </span>
                    ) : ''}
                  </Box>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                   {values.files.length > 0 && (
                     <List sx={{ marginTop: '4px', width: { xs: '100%', sm: '90%' } }}>
                          {values.files.map((file, index) => { 
                     const progress = this.state.uploadProgress[file.name] || 0;
                     const isUploading = this.state.uploadingFile === file.name;
                            return (
                              <ListItem
                                key={index}
                                sx={{
                                  background: '#f1f4f9',
                                  padding: '8px 12px',
                                  borderRadius: '12px',
                                  boxShadow: 'revert',
                                  marginBottom: '10px',
                                  position: 'relative'
                                }}
                              >
                                {isUploading && (
                                  <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      height: 4,
                                      borderRadius: '10px',
                                      '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#e89d24',
                                      },
                                      '& .MuiLinearProgress-bar1Buffer': {
                                        backgroundColor: 'lightyellow',
                                      },
                                      '& .MuiLinearProgress-dashed': {
                                        backgroundColor: '#e89d24',
                                      },
                                      backgroundColor: '#f1f4f9',
                                    }}

                                  />
                                )}
                                <img
                                  src={file.type === 'application/pdf' ? pdfFile.default : file.type === 'image/jpeg' ? jpgFile.default : worldFile.default}
                                  alt="file icon"
                                  style={{ width: '32px', height: '32px', marginRight: '5px' }}
                                />
                                <ListItemText sx={{ fontSize: '13px', fontWeight: 400 }} primary={file.name} secondary={`${(file.size / 1048576).toFixed(2)} Mb`} />
                                {isUploading ? <IconButton
                                  sx={{ cursor: 'pointer', color: "#94a3b8" }}
                                  edge="end"
                                  aria-label="delete"
                                  data-test-id="removeFile"
                                  onClick={() => this.handleRemoveFile(index, setFieldValue, values.files)}
                                >
                                  <CloseIcon />
                                </IconButton> : <IconButton
                                  sx={{ cursor: 'pointer' }}
                                  color='error'
                                  edge="end"
                                  aria-label="delete"
                                  data-test-id="removeFile"
                                  onClick={() => this.handleRemoveFile(index, setFieldValue, values.files)}
                                >
                                  <DeleteIcon />
                                  <span style={{ fontSize: '11px', fontFamily: 'Inter', }}>Delete</span>
                                </IconButton>}
                              </ListItem>
                            );
              })}
            </List>
          )}
        </div>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    alignItems: 'center',
                    marginTop: { xs: '20px', sm: '30px' },
                    gap: { xs: 2, sm: 4 },
                  }}>
                    <Button
                      fullWidth
                      variant="contained"
                      data-test-id='createCaseBtn'
                      sx={{
                        background: 'linear-gradient(90deg, #17588b, #3b83bd, #69beff)',
                        fontWeight: 600,
                        textTransform: 'none',
                        fontFamily:'Inter',
                      }}
                      type='submit'
                    >
                      Create a new case
                    </Button>
                    <Button
                              fullWidth
                              onClick={handleReset}
                              variant="contained"
                              sx={{
                                background: '#f1f4f9',
                                fontWeight: 600,
                                textTransform: 'none',
                                fontFamily: 'Inter',
                                color: '#788393',
                                ":hover": {
                                  background: '#f1f4f9',
                                },
                              }}
                    >
                      Cancel
                    </Button>
                  </Box>

                   </Box>
                </Box> : (this.previewScreenContent(values, setFieldValue))
                  }
                </form>
              )}
            </Formik>
          </Box>
        </Box>

        {this.state.isOpenConfrimationDialog && <Dialog open={true} sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <DialogTitle sx={{ m: 0, p: 2, fontWeight: 600, alignSelf: "center" }}>
            New Case Creation
            <IconButton
              aria-label="close"
              data-test-id="closeIconButton"
              onClick={() => { this.setState({ isOpenConfrimationDialog: false }) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontSize: '14px', paddingTop: '25px', paddingBottom: '25px' }} gutterBottom>
              Are you sure you want to submit? You won't be able to make changes to the case later.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth 
              onClick={this.onAddTask}
              data-test-id='addCaseFormBtn'
              variant="contained"
              sx={{
                background: 'linear-gradient(90deg, #17588b, #3b83bd, #69beff)',
                color: '#fff',
                '&:hover': {
                  background: 'linear-gradient(to right, #17588b, #3b83bd)',
                },
              }}
            >
              Submit
            </Button>
            <Button data-test-id='cancelDialogBtn'  fullWidth onClick={() => { this.setState({ isOpenConfrimationDialog: false }) }} variant="contained" sx={{ backgroundColor: '#f0f0f0', color: '#000' }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>}
      </div> 
      
      </Layout>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
