// Customizable Area Start
import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/material/styles';

// Customizable Area End

import ForgotPasswordController, {
    Props,
  configJSON,
} from "./ForgotPasswordController";

export default class ForgotPasswordOTP extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { minutes, remainingSeconds } = this.convertSecondsToMinutes(this.state.totalSeconds);
    return (
        <Grid container>
            <Grid item xs={12} lg={6}>

                <div
                    style={styles.rootForgorPasswordOTP}
                    onClick={() => this.goTo('ForgotPasswordWeb')}
                    data-test-id="BackButton"
                >
                    <ArrowBackIosIcon style={styles.arrowForgorPasswordOTP} />
                    <div style={styles.backBtnForgorPasswordOTP}>
                        Back
                    </div>
                </div>
                <Grid container sx={styles.inputContainer}>
                    <div style={{ width: '100%' }}>
                        <div style={styles.headImgForgorPasswordOTP}>
                            {<img src={this.state.bigLogo} style={{ width: '100%', height: '100%' }} />}
                        </div>
                    </div>
                    <Box style={{ width: '100%' }}>
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' ,fontFamily:"Inter" }}>
                            <Typography style={{ margin: '20px 0 15px 0', fontSize: '20px' }}>
                                Account verification
                            </Typography>
                            <Typography style={{ textAlign: "center" , fontFamily:"Inter" ,fontSize: '14px'}}>
                                Please enter the verification code sent to your mobile number<br />
                                (SMS code is valid for 10 mins)
                            </Typography>
                        </Box>

                        <Box sx={styles.phoneNumberContainer}>
                            <Typography style={{ margin: '35px 0', fontSize: '20px', color: '#94A3B8',fontFamily:"Inter"  }}>
                                +1 {this.state.phone}
                            </Typography>

                            <Box display="flex" justifyContent="center" gap={2}>
                                {this.state.mobile_otp.map((digit, index) => (
                                    <TextField
                                        data-test-id="otp-input"
                                        key={index}
                                        type="text"
                                        inputProps={{
                                            maxLength: 1,
                                            style: { textAlign: 'center', fontSize: '16px' },
                                        }}
                                        placeholder="0"
                                        sx={{
                                            width: '56px',
                                            height: '56px',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                        value={digit}
                                        onChange={this.handleChange(index)}
                                        onKeyDown={this.handleKeyDown(index)}
                                        inputRef={(input) => (this.inputRefs[index] = input)}
                                    />
                                ))}
                            </Box>
                            {this.state.otpErrorMessage!=="" ?
                                <Box
                                    data-test-id="errorMessage"
                                    style={styles.containerErrorRegisteredUser}>
                                    <Typography style={styles.errorMsg}>
                                        {this.state.otpErrorMessage}
                                    </Typography>
                                </Box> : null}

                                {this.state.resendOtpSuccessMessage ?
                                <Box
                                    data-test-id="successMessage"
                                    style={styles.containerErrorOtpSuccess}>
                                    <Typography style={styles.successMsg}>
                                        {this.state.resendOtpSuccessMessage}
                                    </Typography>
                                </Box> : null}
                            <Box sx={{display: 'flex',gap: '5px',marginBottom: '20px',color: 'gray',marginTop: this.state.otpErrorMessage === "" ? '20px' : undefined}}>
                                <Typography onClick={this.resendPhoneOtp} data-test-id="resendPhoneOtp" style={this.state.totalSeconds===0 ? styles.resendBtnEnabled : styles.resendBtnDisabled}>
                                    Resend code
                                </Typography>
                                {this.state.totalSeconds!==0 && <Typography style={{ color: 'black' ,fontFamily:"Inter" , fontSize:"14px"}}>
                                    in {minutes}:{remainingSeconds} Min
                                </Typography>}
                            </Box>
                        </Box>
                    </Box>

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            data-test-id="continue-btn"
                            variant={"outlined"}
                            sx={{ textTransform: 'capitalize' }}
                            disabled={this.state.btnContinueBlockStatus ? true : false}
                            style={this.state.btnContinueBlockStatus ? styles.continueBtnBlock : styles.continueBtn}
                            onClick={() => this.goToChangePasswordAfterOtpWeb({ mobile_otp: this.state.mobile_otp.join('') })} >
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
                <StyledImgForgorPasswordOTP src={this.state.sidePic} />
            </Grid>
        </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledImgForgorPasswordOTP = styled('img')(({ theme }) => ({
    width: '100%',
    height: '100%',
    marginTop: '42px',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
        height: '73%',
        borderRadius: '64px 0px 0px 64px',
    },
}));
const styles = {
    phoneNumberContainer:{
        display:"flex",
        flexDirection:'column',
        alignItems:'center'
    },
    rootForgorPasswordOTP : {
        display: 'flex',
        alignItems: 'center',
        height: "26px",
        width: '71px',
        marginTop: '30px',
        marginLeft: '34px',
    },
    arrowForgorPasswordOTP : {
        color: '#0F172A',
        cursor: 'pointer',
    },
    backBtnForgorPasswordOTP : {
        fontSize: '18px',
        color: '#0F172A',
        fontFamily:"Inter",
        cursor: 'pointer'
    },
    formForgorPasswordOTP : {
        display: 'flex',
        flexWrap:'wrap',
        marginLeft: {xs:'50px', md:'70px', lg:'105px'},
        marginTop: '80px',
        marginBottom: '20px'
    },
    headImgBoxForgorPasswordOTP:{
      with: '100%',
      heigth: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
    },
    headImgForgorPasswordOTP : {
        margin:"0 auto",
        height: '120px',
        width: '120px',
        paddingLeft:'12px',   
    },

    inputContainer : {
        width: '100%',
        padding: {xs:'0 50px 0 50px', md:'0 70px 0 70px', lg:'0 105px 0 105px'},
    },

    required : {
        color: 'red',
        marginLeft: '3px'
    },

    errorMsg : {
        color:'#DC2626',
        fontSize:'14px',
        lineheigth: '22px',
        fontWeight:'400',
        align : 'center'
    },
    successMsg : {
        color:'#047857',
        fontSize:'14px',
        lineheigth: '22px',
        fontWeight:'400',
        align : 'center'
    },
    continueBtn : {
        width: '100%',
        height: '56px',
        fontSize: '15px',
        color: 'white',
        background: 'linear-gradient(to right, #17588b, #3b83bd, #69beff)',
        marginTop: '8px',
        border: 'none',

    },
    continueBtnBlock : {
        width: '60%',
        height: '56px',
        fontSize: '15px',
        background: '#F1F4F9',
        marginTop: '8px',
        border: 'none',
        color:'#798189',
    },
    resendBtnEnabled:{
        color:"#3b83bd",
        fontFamily: "Inter",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "22px",
        cursor:"pointer"
    },
    resendBtnDisabled:{
        fontFamily: "Inter",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "22px",
    },
    containerErrorRegisteredUser:{
        width:"100%",
        display:'flex',
        justifyContent:"center",
        marginBottom: '20px',
    },
    containerErrorOtpSuccess:{
        width:"100%",
        display:'flex',
        justifyContent:"center",
        marginTop: '20px',
    }
};


// Customizable Area End
