//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import CancelIcon from '@mui/icons-material/Cancel';
// Customizable Area Start
import {
  Container,
  Input,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box, Typography, Button,
  Pagination,
  Popover,
  IconButton,
  TextField,
  Stack
} from "@mui/material";
import CircularProgress, {
  circularProgressClasses
} from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { plus, vertDots,logo ,done,upload, error} from "./assets";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Layout from "../../landingpage/src/Layout.web";
import { ToastContainer } from "react-toastify";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const CustomImage = styled("img")({
  width: "100%",
  height: "168px",
  borderRadius: "8px 8px 0 0",

  "@media(max-width: 599px)": {
    height: "100%",
    borderRadius: "8px 0 0 8px",
    width: "250px"
  },
  "@media(max-width: 430px)": {
    width: "201px"
  },
})
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  AddDocument = () => {
    return (
      <Box sx={webStyles.newDocCard} data-test-id="handleAdd" onClick={this.handleAdd}>
        <Box style={webStyles.newDoclabelContainer}>
          <Typography style={webStyles.addDocHeading}>
            Add new document
            <span style={webStyles.iconWrapper}>
              <img src={plus} alt="Add" style={webStyles.icon} />
            </span>
          </Typography>
        </Box>
      </Box>
    )
  }
  circularProgress(open: boolean) {
    return (
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}
      >
        <CircularProgress
                variant="indeterminate"
                sx={(theme) => ({
                    color: '#3b83bd',
                    animationDuration: '1000ms',
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                        strokeDasharray: '100px, 200px',
                        strokeDashoffset: '0px',
                    },
                    ...theme.applyStyles('dark', {
                        color: '#308fe8',
                    }),
                })}
                size={50}
                thickness={5}
            />
      </Backdrop>
    );
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Layout id="ContentManagement" navigation={this.props.navigation}>
        <>
        <ToastContainer/>
        <div sx={{ fontFamily: 'Inter', height: "100%", width: '100%' }}>
        <style>
          {`
            .Mui-selected {
              font-family: 'Inter'; 
              font-size: 12px;
              font-weight: 700;
              line-height: 18px;
              color: #3b83bd;
              background-color: transparent !important;
              text-decoration: none !important;
            }

            .MuiPaginationItem-root {
              font-family: 'Inter'; 
              font-size: 12px;
              font-weight: 700;
              line-height: 18px;
              text-decoration: underline;
              background-color: transparent;
            }
          `}
        </style>
          <Box margin={{xs: "24px", md: "30px"}}>
            <Typography sx={{...webStyles.pageHeading, fontSize: { xs: "20px", md: "clamp(0.5rem, 5vw, 2rem)" }}}>Health Wallet</Typography>
            <Box sx={{ marginLeft: {xs: 0, md: "30px"} }}>
              <Typography sx={webStyles.gridHeading}>Explore your medical documents</Typography>
              {this.state.isLoading && this.circularProgress(this.state.isLoading)}
                {
                  this.state.cardData.length > 0 ?
                    <Box sx={{ paddingLeft: {xs: 0, md: "30px"}, display: "flex", flexDirection: {xs: "column-reverse", md: "column"} }}>
                      <Grid container style={webStyles.container}>
                        {this.state.cardData.map((item: WalletResponse, index: number) => (
                          <Grid item lg={2} md={3} sm={5} xs={12} key={index}>
                            <Box sx={{...webStyles.card, display: {xs: "flex", md: "block"}, alignItems: "center"}}>
                              <CustomImage src={item.document_url.url} style={webStyles.cardImg} />
                              <Box display="flex" flexDirection={{xs: "column-reverse", md: "column"}} justifyContent={{xs: "flex-end", md: "space-between"}} height="100%" maxHeight="120px" sx={{width: {xs: "100%"}}}>
                                <Box style={webStyles.labelContainer}>
                                  <Typography style={webStyles.cardHeading}>{item.wallet.document_name}</Typography>
                                  <Typography style={webStyles.cardSubHeading}>File Size: {item.document_url.size}</Typography>
                                </Box>
                                <Box style={webStyles.vertDotsContainer}>
                                  <Button style={webStyles.vertDotBtn} data-test-id="moreOption" color="primary" onClick={(event) => this.handleClick(event, item)}>
                                    <img src={vertDots} />
                                  </Button>
                                  <Popover
                                    PaperProps={{
                                      sx: {
                                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                        borderRadius: "8px, 0px, 8px, 8px"
                                      },
                                    }}
                                    id="simple-menu"
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleClose}
                                    style={webStyles.menu}
                                    data-test-id="popover"
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <MenuItem style={webStyles.menuItem} data-test-id="handleView" onClick={() => this.handleView(this.state.selectedItem.wallet.id)}>View</MenuItem>
                                    <MenuItem style={webStyles.menuItem} data-test-id="handleDownload" onClick={() => this.handleDownload({url: this.state.selectedItem.document_url.url, name: this.state.selectedItem.wallet.document_name})} download>Download</MenuItem>
                                    <MenuItem style={webStyles.menuItem} data-test-id="handleRename" onClick={() => this.handleRename(this.state.selectedItem.wallet.id)}>Rename</MenuItem>
                                    <MenuItem style={{ ...webStyles.menuItem, color: "#DC2626" }} data-test-id="handleDelete" onClick={this.handleDelete}>Delete</MenuItem>
                                  </Popover>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      {this.AddDocument()}
                    </Box> :
                    <Box style={webStyles.emptyBox}>
                      <Typography>No documents added yet</Typography>
                      {this.AddDocument()}
                    </Box>
                }
            </Box>            
            <Box style={webStyles.paginationBox}>
              {this.state.cardData.length > 0  && <Pagination
                data-test-id="pagination"
                count={Math.ceil(this.state.totalItems / this.state.itemsPerPage)}
                page={this.state.currentPage}
                onChange={this.handlePageChange}
                boundaryCount={1}
                hidePrevButton={this.state.currentPage===1}
                hideNextButton={this.state.currentPage===Math.ceil(this.state.totalItems / this.state.itemsPerPage)}
              />}
            </Box>
          </Box>
        </div>

        <Dialog onClose={this.handleViewDialogClose} aria-labelledby="customized-dialog-title" open={this.state.viewDocument} sx={{
          ".MuiDialog-paperWidthSm": {
            width: "100%"
          }
        }}>
          <Box sx={{position: "absolute", top: "10px", right: "10px"}}>
            <IconButton aria-label="close" data-test-id="closeViewDialog" style={{ float: "right" }} onClick={this.handleViewDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent sx={{width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={this.state.singleDocument} style={webStyles.dialogImage} />
          </DialogContent>
        </Dialog>

        <Dialog onClose={this.handleRenameDialogClose} maxWidth={"md"} aria-labelledby="customized-dialog-title" open={this.state.renameDocument}>
          <Box>
            <IconButton aria-label="close" data-test-id="closeRenameDialog" style={{ float: "right" }} onClick={this.handleRenameDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent >
            <Box sx={webStyles.formRenameDocument}>
              <Grid container sx={webStyles.inputContainer}>
                <div style={{ width: '100%' }}>
                  <div style={webStyles.headImgRenameDocument}>
                    {<img src={logo} style={webStyles.imgRenameDocument} />}
                  </div>
                </div>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.head1}>
                    Renaming
                  </Typography>
                </Box>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.head3}>
                    Please rename your document while filling in the filed below
                  </Typography>
                </Box>

                  <Grid item xs={12}>
                    <div>
                      <div style={webStyles.inputLabelHeading}>Document information</div>
                      <div style={webStyles.inputLabel}>Document Name</div>
                      <TextField
                        data-test-id="documentNameField"
                        name='documentNameField'
                        placeholder="Medical card"
                        variant="outlined"
                        value={this.state.documentName}
                        fullWidth
                        margin="normal"
                        required
                        style={webStyles.phoneInput}
                        onChange={(e: any) => { this.handleDocumentName(e) }}
                      />
                    </div>
                    {
                      <Box sx={webStyles.errorMsg}>{this.renderErrorMessage()}</Box>
                    }
                  </Grid>

                <Grid item xs={12} lg={12}>
                  <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="renameDialogContinue"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.continueBtn}
                        onClick={()=>this.renameDocApi(this.state.selectedItem.wallet.id)} >
                        Rename document
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="renameDialogCancel"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.cancelBtn}
                        onClick={this.handleRenameDialogClose}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box >
          </DialogContent>
        </Dialog>

        <Dialog data-test-id="deleteDialogClose" onClose={this.handleDeleteDialogClose} maxWidth={"lg"} aria-labelledby="customized-dialog-title" open={this.state.deleteDocument}>
          <Box>
            <IconButton aria-label="close" data-test-id="closeDeleteDialog" style={{ float: "right" }} onClick={this.handleDeleteDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent >
            <Box sx={webStyles.formDeleteDocument}>
              <Grid container sx={webStyles.inputContainer}>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.deleteHead1}>
                    Deleting Document
                  </Typography>
                </Box>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.deleteHead3}>
                  Are you sure you want to delete this document?
                  </Typography>
                </Box>

                <Grid item xs={12} lg={12} >
                  <Grid container spacing={2} justifyContent="space-between" alignItems="center" marginTop="20px">
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="deleteDialogContinue"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.cancelDeleteBtn}
                        onClick={this.handleDeleteDialogClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="deleteDialogCancel"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.deleteBtn}
                        onClick={()=>this.deleteDocApi(this.state.selectedItem.wallet.id)}
                        >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box >
          </DialogContent>
        </Dialog>

        <Dialog data-test-id="deleteSuccessDialog" onClose={this.handleDeleteSuccessDialogClose} maxWidth={"lg"} aria-labelledby="customized-dialog-title" open={this.state.deleteSuccessDocument} sx={{
          ".MuiDialog-paperWidthLg": {
            borderRadius: "12px"
          }}}>
          <DialogContent sx={{width: {md: "435px"}, height: "295px", borderRadius: "12px", display: "flex", alignItems: "center"}}>
              <div style={{...webStyles.headImgDeleteDocument, position: "absolute", top: "21px", left: "25px"}}>
                    {<img src={logo} />}
              </div>
            <Box sx={{...webStyles.formDeleteSuccessDocument, height: "103px", marginLeft: 0, marginBottom: 0, marginTop: 0, width: "100%"}}>
              <Stack sx={{...webStyles.inputContainer, padding: 0}} gap={"8px"}>
                <div style={{ width: '100%' }}>
                  <div style={webStyles.doneImgDeleteDocument}>{<img src={done} />}</div>
                </div>
                <Stack gap={"4px"}>
                  <Box style={webStyles.containerLabelInputNumber}>
                    <Typography style={{...webStyles.deleteHead1, color: "#0F172A"}}>Completed</Typography>
                  </Box>
                  <Box style={webStyles.containerLabelInputNumber}>
                    <Typography style={{...webStyles.deleteHead3, color: "#475569", width: "100%"}}>Your document was deleted successfully</Typography>
                  </Box>
                </Stack>
              </Stack>
            </Box >
          </DialogContent>
        </Dialog>

        <Dialog onClose={this.handleAddDialogClose} maxWidth={"md"} aria-labelledby="customized-dialog-title" open={this.state.addDocument}>
          <Box>
            <IconButton aria-label="close" data-test-id="closeAddDialog" style={{ float: "right" }} onClick={this.handleAddDialogClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent >
            <Box sx={webStyles.formUploadDocument}>
              <Grid container sx={webStyles.inputContainer}>
                <div style={{ width: '100%' }}>
                  <div style={webStyles.headImgRenameDocument}>
                    {<img src={logo} />}
                  </div>
                </div>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.head1}>
                    Adding new document
                  </Typography>
                </Box>
                <Box style={webStyles.containerLabelInputNumber}>
                  <Typography style={webStyles.head3}>
                  Please fill in all mandatory fields below to add new medical document
                  </Typography>
                </Box>

                <Grid item xs={12}>
                  <div>
                    <div style={webStyles.inputLabelHeading}>Document information<span style={{color:"red"}}>*</span></div>
                    <div style={webStyles.inputLabel}>Document Name</div>
                    <TextField
                      data-test-id="uploadDocumentNameField"
                      name='uploadDocumentNameField'
                      placeholder="Medical card"
                      variant="outlined"
                      value={this.state.uploadDocumentName}
                      fullWidth 
                      margin="normal"
                      required 
                      sx={webStyles.phoneInput}
                      InputProps={{
                        style: {border: this.state.inputErrors.titleError ? "1px solid #DC2626" : ""}
                      }}
                      onChange={(e: any) => {this.handleUploadDocumentName(e)}}
                    />
                    {this.state.inputErrors.titleError && <Typography sx={{
                      color: '#DC2626',
                      fontSize: '12px',
                      fontWeight: '400',
                      fontFamily: "Inter"
                    }}>{this.state.inputErrors.titleError}</Typography>}
                  </div>
                </Grid>

                <Grid item xs={12}>
                    <div>
                      <div style={webStyles.inputLabelHeading}>Image uploading<span style={{color:"red"}}>*</span></div>
                      <Box className="drag-drop" sx={{margin:{xs: "5% 0", md: "5% 15%"}}}>
                        <div
                          data-test-id="document-uploader"
                          className={`document-uploader ${this.state.file ? "upload-box active" : "upload-box"}`}
                          onDrop={this.handleDrop}
                          onDragOver={(event) => event.preventDefault()}
                          style={{...webStyles.uploadImageContainer, borderColor: "rgb(203, 213, 225"}}
                        >
                          <div className="upload-info" style={webStyles.uploadInfo}>
                            <Box style={webStyles.uploadImgWrapper}>
                              <img src={upload}/>
                            </Box>
                            <div style={{textAlign:"center"}}>
                              <p style={webStyles.support}>Supported file: pdf, jpg, png</p>
                              <p style={webStyles.support}>Maximum size: 10 Mb</p>
                              {(this.state.inputErrors.fileError && !this.state.inputErrors.fileError.includes("Please choose a document")) && (
                                  <Dialog data-test-id="errorDialog" open={true} onClose={() => this.setState({ inputErrors: {...this.state.inputErrors, fileError: ""} })} sx={{
                                    ".MuiDialog-paperWidthSm": {
                                      borderRadius: "12px",
                                      maxWidth: "435px",
                                      width: "100%"
                                    }}}>
                                    <DialogContent sx={{width: {lg: "435px"}, height: "295px", borderRadius: "12px", display: "flex", alignItems: "center"}}>
                                        <div style={{...webStyles.headImgDeleteDocument, position: "absolute", top: "21px", left: "25px"}}>{<img src={logo} width={"100%"} height={"100%"} />}</div>
                                      <Box sx={{...webStyles.formDeleteSuccessDocument, height: "103px", marginLeft: 0, marginBottom: 0, marginTop: 0, width: "100%"}}>
                                        <Stack sx={{...webStyles.inputContainer, padding: 0}} gap={"8px"}>
                                          <div style={{ width: '100%' }}>
                                            <div style={{...webStyles.doneImgDeleteDocument, display: "flex", alignItems: "center", justifyContent: "center"}}>{<img src={error} />}</div>
                                          </div>
                                          <Stack gap={"4px"}>
                                            <Box style={webStyles.containerLabelInputNumber}>
                                              <Typography style={{...webStyles.deleteHead1, color: "#0F172A"}}>Error when uploading</Typography>
                                            </Box>
                                            <Box style={webStyles.containerLabelInputNumber}>
                                              <Typography style={{...webStyles.deleteHead3, color: "#475569", width: "100%", fontWeight: 400}}>{this.state.inputErrors.fileError}</Typography>
                                            </Box>
                                          </Stack>
                                        </Stack>
                                      </Box >
                                    </DialogContent>
                                  </Dialog>
                              )}
                            </div>
                          </div>
                          <input
                            type="file"
                            data-test-id="browse"
                            hidden
                            id="browse"
                            onChange={this.handleFileChange}
                            accept=".pdf, .jpg, .png"
                          />
                          <Button variant="contained" style={webStyles.chooseBtn}>
                            <label htmlFor="browse" style={webStyles.chooseBtnText}>
                              Choose file
                            </label>
                          </Button>

                          {this.state.documentFile && (
                            <p>1 file selected</p>
                          )}
                        </div>
                      </Box>
                    </div>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="uploadDialogContinue"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.continueBtn}
                        onClick={()=>this.uploadDocApi()} >
                        Add document
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                      <Button
                        data-test-id="uploadDialogCancel"
                        variant={"outlined"}
                        sx={{ textTransform: 'capitalize', fontFamily: "Inter" }}
                        style={webStyles.cancelBtn}
                        onClick={this.handleAddDialogClose}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box >
          </DialogContent>
        </Dialog>

        <Dialog data-test-id="uploadSuccessDialog" onClose={this.handleUploadSuccessDialogClose} maxWidth={"lg"} aria-labelledby="customized-dialog-title" open={this.state.addSuccessDocument} sx={{
          ".MuiDialog-paperWidthLg": {
            borderRadius: "12px"
          }}}>
          <DialogContent sx={{width: {md: "435px"}, height: "295px", borderRadius: "12px", display: "flex", alignItems: "center"}}>
              <div style={{...webStyles.headImgDeleteDocument, position: "absolute", top: "21px", left: "25px"}}>{<img src={logo} width={"100%"} height={"100%"} />}</div>
            <Box sx={{...webStyles.formDeleteSuccessDocument, height: "103px", margin: 0, width: "100%"}}>
              <Stack sx={{...webStyles.inputContainer, p: 0}} gap={"8px"}>
                <div style={{ width: '100%' }}>
                  <div style={{...webStyles.doneImgDeleteDocument}}>{<img src={done} />}</div>
                </div>
                <Stack gap={"4px"}>
                  <Box style={{...webStyles.containerLabelInputNumber}}>
                    <Typography style={{...webStyles.deleteHead1, color: "#0F172A"}}>Completed</Typography>
                  </Box>
                  <Box style={{...webStyles.containerLabelInputNumber}}>
                    <Typography style={{...webStyles.deleteHead3, color: "#475569", width: "100%"}}>Your document was created successfully</Typography>
                  </Box>
                </Stack>
              </Stack>
            </Box >
          </DialogContent>
        </Dialog>
        </>
      </Layout>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const webStyles = {
  body: {
    height: "100%",
    width: "100%"
  },
  ImgTagStyle: {
    width: '100px',
    height: '100px',
    padding: '10px'
  },
  card: {
    width: {xs: "100%", md: "200px"},
    height: {xs: "184px", md: "311px"},
    opacity: "0px",
    background: "#F1F5F9",
    borderRadius: "16px",
    border: "8px solid #E2E8F0",
  },
  container: {
    gap: "24px",
  },
  labelContainer: {
    padding: "6.5px",
  },
  cardHeading: {
    color: "#334155",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "26px",
    fontFamily: "Inter",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis"
  },
  cardSubHeading: {
    color: "#334155",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Inter"
  },
  pageHeading: {
    fontWeight: 700,
    lineHeight: "32px",
    fontFamily: "Inter"
  },
  newDocCard: {
    width: {xs: "100%", md: "200px"},
    height: "62px",
    background: "#fff",
    borderRadius: "16px",
    border: "8px solid #E2E8F0",
    position: "relative",
    margin: "2% 0",
    cursor: "pointer"
  },
  gridHeading: {
    fontSize: {xs: "24px", md: "clamp(0.5rem, 7vw, 2.5rem)"},
    fontWeight: 700,
    lineHeight: "44px",
    margin: "3% 0",
    fontFamily: "Inter"
  },
  paginationBox: {
    justifyContent: "center",
    display: "flex"
  },
  vertDotsContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  vertDotBtn: {
    cursor: "pointer",
    padding: "10px"
  },
  newDoclabelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  addDocHeading: {
    color: "#334155",
    fontWeight: 400,
    fontSize: "14px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
  iconWrapper: {
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  menuItem: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textAlign: "right",
    color: "#475569",
    // borderBottom: "1px solid #CBD5E1",
  },
  menu: {
    border: "1px solid #CBD5E1"
  },
  dialogImage: {
    borderRadius: "8px",
    border: "20px solid #F1F5F9",
    maxWidth: '100%', 
    maxHeight: '100%', 
    objectFit: 'contain'
  },
  containerErrorRegisteredUser: {
    width: "100%",
    display: 'flex',
    justifyContent: "center"
  },
  containerLabelInputNumber: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  rootForgotPassword: {
    display: 'flex',
    alignItems: 'center',
    height: "26px",
    width: '71px',
    marginTop: '58px',
    marginLeft: '34px',
  },
  arrowForgotPassword: {
    color: '#0F172A'
  },
  backBtnForgotPassword: {
    fontSize: '18px',
    color: '#0F172A',
    fontFamily: "Inter"
  },
  formRenameDocument: {
    heigth: '100%',
    maxHeight:"524px",
    maxWidth:"783px",
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: { xs: '30px', md: '50px', lg: '80px' },
    marginTop: '50px',
    marginBottom: '20px',
    borderRadius: "16px"
  },
  formUploadDocument:{
    heigth: '100%',
    maxHeight:"766px",
    maxWidth:"880px",
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: { xs: '0', md: '50px', lg: '80px' },
    marginBottom: '20px',
    borderRadius: "16px",
  },
  formDeleteDocument: {
    // heigth: '100%',
    maxHeight:"327px",
    maxWidth:"562px",
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: { xs: '30px', md: '50px', lg: '80px' },
    marginTop: '50px',
    marginBottom: '20px',
    borderRadius: "16px"
  },
  formDeleteSuccessDocument:{
    // heigth: '100%',
    maxHeight:"295px",
    maxWidth:"435px",
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: { xs: '30px', md: '50px', lg: '80px' },
    marginTop: '50px',
    marginBottom: '20px',
    borderRadius: "16px"
  },
  headImgBoxForgotPassword: {
    with: '100%',
    heigth: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
  },
  imgRenameDocument: {
    width: '80px',
  },
  headImgRenameDocument: {
    margin: "0 auto",
    height: '80px',
    width: '70px',
  },
  doneImgDeleteDocument: {
    margin: "0 auto",
    height: '50px',
    width: '50px',
  },
  headImgDeleteDocument: {
    height: '48px',
    width: '48px',
  },
  head1:{
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "center",
  },
  head3: {
    marginBottom: '20px',
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textAlign: "center",
  },
  inputContainer: {
    heigth: '100%',
    width: '100%',
    padding: { xs: '0', md: '0 70px 0 0px', lg: '0 105px 0 0px' },
  },
  inputLabel: {
    fontSize: '14px',
    display: 'flex',
    fontWeight: '700',
    fontFamily: "Inter",
    lineHeight: "22px",
    color:"#64748B"
  },
  inputLabelHeading:{
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left",
    color:"#64748B"
  },
  phoneInput: {
    height: '56px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: '400',
    width:"250px"
  },
  continueBtn: {
    width: '100%',
    height: '56px',
    fontSize: '15px',
    color: 'white',
    background: 'linear-gradient(to right, #17588b, #3b83bd, #69beff)',
    marginTop: '8px',
    border: 'none',
  },
  cancelBtn:{
    width: '100%',
    height: '56px',
    fontSize: '15px',
    color: 'black',
    marginTop: '8px',
    border:"none",
    background: "#F1F5F9",
  },
  continueBtnBlock: {
    width: '100%',
    height: '56px',
    fontSize: '15px',
    background: '#F1F5F9',
    marginTop: '8px',
    border: 'none',
    color: '#64748B',
  },
  deleteHead1:{
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "center",
    color:"#DC2626"
  },
  deleteHead3:{
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "center",
    color:"#64748B",
  },
  cancelDeleteBtn:{
    width: '100%',
    height: '56px',
    fontSize: '15px',
    color: 'white',
    background: 'linear-gradient(to right, #17588b, #3b83bd, #69beff)',
    marginTop: '8px',
    border: 'none',
  },
  deleteBtn:{
    width: '100%',
    height: '56px',
    fontSize: '15px',
    color: '#F87171',
    marginTop: '8px',
    border:"none",
    background: "#F1F5F9",
  },
  uploadImgWrapper: {
    padding: "10px",
    backgroundColor: "#3b83bd",
    width: "60px",
    height: "60px",
    borderRadius: "77px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  uploadImageContainer:{
    width: {xs: "100%", md: "450px"},
    minHeight: "193px",
    border: "2px dashed #CBD5E1",
    padding: "20px",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    borderRadius:"8px"
  },
  uploadInfo:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  support:{
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: {md: "5px"},
    color:"#94A3B8"
  },
  chooseBtn:{
    width: "130px",
    height: "34px",
    borderRadius: "8px",
    background: "#94A3B8"
  },
  chooseBtnText:{
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color:"#fff"
  },
  emptyBox:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  errorMsg : {
    color:'#DC2626',
    fontSize:'12px',
    fontWeight:'400',
    marginBottom: '20px',
    fontFamily:"Inter"
},

}

const StyledImgForgotPassword = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  marginTop: '42px',
  marginBottom: '40px',
  [theme.breakpoints.up('md')]: {
    height: '73%',
    borderRadius: '64px 0px 0px 64px',
  },
}));
// Customizable Area End
