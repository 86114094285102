// Customizable Area Start
import React from "react";
import EmailAccountLoginController, { AuthenticationMethod, Props } from "./EmailAccountLoginController";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OtpInput from 'react-otp-input';
import { styled } from '@mui/material/styles';
import Timer from './components/Timer';


export default class OTPVerification extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        this.getOTPInputStyle = this.getOTPInputStyle.bind(this)
        this.getEmailOTPInputStyle = this.getEmailOTPInputStyle.bind(this)
    }

    getOTPInputStyle = (props: any) => {
        return {
            ...props.style,
            display: 'flex',
            borderColor: this.state.otpError ? 'red' : '#CBD5E1',
            width: '56px',
            height: '56px',
            alignItems: 'center',
            border: '1px solid #CBD5E1',
            justifyContent: 'center',
            borderRadius: '8px',
            fontFamily: "Inter",
            fontSize: '16px',
            fontWeight: 400,
            padding: '0',
            margin: '8px',
            backgroundColor: 'rgba(255, 255, 255, 1)',
        };
    }

    getEmailOTPInputStyle = (props: any) => {
        return {
            ...props.style,
            borderColor: this.state.otpEmailError ? 'red' : '#CBD5E1',
            width: '56px',
            display: 'flex',
            alignItems: 'center',
            height: '56px',
            justifyContent: 'center',
            borderRadius: '8px',
            border: '1px solid #CBD5E1',
            fontSize: '16px',
            fontFamily:"Inter",
            fontWeight: 400,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            padding: '0',
            margin: '8px',
        }
    }

    renderInput = (props: React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
        const { AuthMethod } = this.state;
        return (
          <input
            {...props}
            placeholder="0"
            style={AuthMethod === AuthenticationMethod.PHONE ? this.getOTPInputStyle(props) : this.getEmailOTPInputStyle(props)}
          />
        );
      };
    
    render() {
        const { AuthMethod } = this.state;
        return (
        <>
        {this.state.verification && (
            <Grid 
             style={styles.FontFamily}
             container 
             >
                <Grid item 
                 md={6}
                 xs={12} 
                >
                    <Grid 
                     container
                    >
                        <Grid 
                         xs={12}
                         item 
                        >
                            <div 
                             data-test-id="backBtn" 
                             style={styles.Main} 
                             onClick={this.navTo}>
                                <ArrowBackIosIcon 
                                   style={styles.backArrow} 
                                   />
                                <div 
                                 style={styles.backButton}
                                 >
                                    Back
                                </div>
                            </div>
                            <Grid 
                             sx={styles.otpWrapper}
                             container 
                             >
                                <Grid 
                                 style={styles.gridStyle}
                                 xs={12} 
                                 item 
                                 >
                                    <div 
                                     style={styles.headImage}
                                    >
                                        <img 
                                          src={this.state.head} 
                                        />
                                    </div>
                                </Grid>
                                <Grid 
                                 xs={12}
                                 item 
                                >
                                    <Typography sx={styles.phone}>
                                        {AuthMethod === AuthenticationMethod.PHONE ? 'Phone number verification' : 'Email verification'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.verification}>
                                        {AuthMethod === AuthenticationMethod.PHONE
                                            ? 'Please enter the verification code sent to your mobile number.'
                                            : 'Please enter the verification code sent to your email address.'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.valid}>
                                        (Code is valid for 10 mins)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={styles.phoneNumber}>
                                        {AuthMethod === AuthenticationMethod.PHONE ? this.state.phoneNumber : this.state.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={styles.otpBox}>
                                        <OtpInput
                                            value={AuthMethod === AuthenticationMethod.PHONE? this.state.otp : this.state.emailOtp}
                                            inputType="tel"
                                            onChange={AuthMethod === AuthenticationMethod.PHONE ? this.handlePhoneOtp : this.handleEmailAddOtp}
                                            containerStyle={{ outline: 'none' }}
                                            data-test-id={AuthMethod === AuthenticationMethod.PHONE ? 'otpInput' : 'emailOtpInput'}
                                            numInputs={4}
                                            renderInput={this.renderInput}
                                            // renderInput={(props) => <input {...props} placeholder="0" style={AuthMethod === AuthenticationMethod.PHONE ? this.getOTPInputStyle(props) : this.getEmailOTPInputStyle(props)} />}
                                        />
                                    </Box>
                                    {AuthMethod === AuthenticationMethod.PHONE && this.state.otpError && (
                                        <Box sx={styles.otpError}>{this.state.otpPhoneErrorMessage}</Box>
                                    )}
                                    {AuthMethod === AuthenticationMethod.EMAIL && this.state.otpEmailError && (
                                        <Box sx={styles.otpError}>{this.state.otpEmailErrorMessage}</Box>
                                    )}
                                    {this.state.resendCodeSent && (
                                        <Box sx={{ ...styles.otpError, color: '#047857' }}>
                                            The code was sent successfully
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Timer
                                        onResenCode={this.handleResendOtp}
                                        styles={{
                                            resend: styles.resend,
                                            resendBtn: styles.resendBtn,
                                            time: styles.time,
                                            resendRedBtn: styles.resendRedBtn,
                                            btn: styles.btn,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={styles.grid2}>
                                    <Button
                                        variant="contained"
                                        sx={styles.Btn}
                                        onClick={AuthMethod === AuthenticationMethod.PHONE ? this.handlePhoneSubmit : this.handleOtpEmail}
                                        data-test-id={AuthMethod === AuthenticationMethod.PHONE ? 'verify' : 'emailVerify'}
                                    >
                                        Continue
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={styles.imgBox}>
                        <CustomImage src={this.state.rightPic} />
                    </Box>
                </Grid>
            </Grid>
        )} 
    </>
        )
    }
}

const CustomImage = styled('img')(({ theme }) => ({
    marginTop: '42px',
    width: '100%',
    height: '100%',
    marginBottom: '40px',
    objectFit:'cover',
    [theme.breakpoints.up('md')]: {
        height: '73%',
        borderRadius: '64px 0px 0px 64px',
    },
}));
const styles = {
    Main : {
        display: 'flex',
        width: '71px',
        marginTop: '58px',
        height: "26px",
        marginLeft: '34px',
        alignItems: 'center',
    },
    backArrow : {
        cursor: 'pointer',
        color: '#0F172A',
    },
    backButton : {
        fontSize: '18px',
        cursor: 'pointer',
        fontFamily:"Inter",
        color: '#0F172A',
    },
    otpWrapper : {
        padding: { xs: '0 50px 0 50px', md: '0 70px 0 70px', lg: '0 105px 0 105px' }, display: 'flex',
        alignItems: 'center', 
        width: '100%',
        margin: {sm:'13px 0 0 0', md: '13px 0 0 0', lg:'120px 0 0 0'},
    },
    gridStyle : {
        display: 'flex',
        justifyContent: 'center',
    },
    headImage : {
        height: '100px',
        display: 'flex',
        width: '100px',
        justifyContent: 'center',
    },
    phone : {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        flexWrap: 'wrap',
        margin: '17px 0px 10px 0px',
        width: '100%',
        fontSize: '24px',
        fontFamily:"Inter",
        fontWeight: '400',
    },
    verification : {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        width: '100%',
        fontSize: '14px',
        fontWeight: '400',
        margin: '3px 0px',
        fontFamily:"Inter"
    },
    valid : {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        fontSize: '14px',
        color: '#64748B',
        fontFamily:"Inter",
        fontWeight: '400',
        margin: '3px 0px',
    },
    signingIn:{
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center',
        width: '100%',
        fontSize: '20px',
        fontFamily:"Inter",
        fontWeight: '400',
        color: '#64748B',
        margin: '15px 0px',
    },
    phoneNumber : {
        width: '100%',
        fontFamily:"Inter",
        fontSize: '20px',
        fontWeight: '400',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        margin: '30px 0px',
    },
    otpBox : {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        fontFamily:"Inter",
        marginBottom: {sm: '0px', md: '0px', lg:  '30px'},
    },
    otpInput : {
      
    },
    otpError : {
        display:'flex',
        justifyContent:'center',
        fontFamily:"Inter",
        fontWeight: '400',
        color: '#DC2626',
        fontSize: '14px',
        width:'100%',
    },
    resend : {
        width: '100%',
        fontFamily:"Inter",
        fontWeight: '600',
        fontSize: '14px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        margin: '30px 0px',
    },
    resendRedBtn : {
        color: '#3b83bd'

    },
    btn: {
        fontFamily:"Inter",
        fontWeight: 600,
        fontSize: "14px",
        marginRight: '4px',
        padding: 0,
        textTransform: "none",
    },
    resendBtn : {
        fontFamily:"Inter",
        color: '#A8A29E',
        marginRight: '4px',
    },
    time : {
        fontFamily:"Inter",
        color: '#0F172A',
    },
    Btn : {
        width: '100%',
        height: '56px',
        fontFamily:"Inter",
        fontSize: '15px',
        background: 'linear-gradient(to right, #17588b, #3b83bd, #69beff )',
    },
    grid2 : {
        width: '100%'
    },
    imgBox : {
        display: 'flex',
        justifyContent: 'center',
        width: "100%"
        // marginTop: '20px',
        // height: { xs: '100%', md: '115%', lg: '120%' },
    },
    success: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        fontSize: "30px",
        color: "#059669",
        fontWeight: "400",
        fontFamily:"Inter"
    },
    successBox: {
        marginTop:'20px',

    },
    successContainer : {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: { xs: '0 50px 0 50px', md: '0 70px 0 70px', lg: '0 105px 0 105px' },
        margin: { xs: '100px 0 100px 0',md: '200px 0 0 0', lg: '300px 0 0 0' },
    },
    successheadImg : {
        display: 'flex',
        justifyContent: 'center',
        height: '120px',
        width: '120px',
    },
    successHero : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "26px",
        width: '71px',
        marginLeft: '34px',
        marginTop: '58px',
        
    },
    FontFamily:{
        fontFamily:"Inter",
    }
};

// Customizable Area End